import { FoUtility } from "../../core";
import { FoSize, FoState } from "../../enum";
import { FoChildrenProps } from "../../props";

export interface FoBadgeProps extends FoChildrenProps {
    state?: FoState;
    size?: FoSize;
}

export function FoBadge(props: FoBadgeProps) {
    return (
        <span className={`fo-badge ${FoUtility.size(props.size)} ${FoUtility.state(props.state)}`}>{props.children}</span>
    );
}
