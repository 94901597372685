import { ConfigurationDto } from '../../_core/model/configurationDto';
import { WarningPaginateResult } from '../../_core/model/warningPaginateResult';
import { WarningSpecification } from '../../_core/model/warningSpecification';
import Detail from '../../common/detail';
import { FoField } from '../../framework/components/inputs/field';
import { FoHeading } from '../../framework/components/widgets/heading';
import { FoSpinner } from '../../framework/components/widgets/spinner';
import { FoUtility } from '../../framework/core';
import { FoTryCatch } from '../../framework/decorator/error.decorator';
import { FoLoading } from '../../framework/decorator/loading.decorator';
import { FoConfirm } from '../../framework/decorator/modal.decorator';
import { FoForm } from '../../framework/form';
import FoPage from '../../framework/layout/page';
import { FoLoaderService } from '../../framework/services/loader.service';
import { FoNotifyService } from '../../framework/services/notify.service';
import { BasePage } from '../page.base';

export class Settings extends BasePage<any, {
    settings: ConfigurationDto[],
    settingHash: number,
    meteoHash: number,
    segnalazioniHash: number
}> {

    constructor(props: any) {
        super(props);
        this.setState({ settings: [] });
    }

    private get(key: string): string { return this.state.settings?.find(c => c.key == key)?.value ?? ""; }
    private set(key: string, value: string): void { this.state.settings!.find(c => c.key == key)!.value = value; this.setState({ settings: this.state.settings }) }

    @FoLoading("settings")
    @FoTryCatch({ message: () => "Errore durante il recupero delle impostazioni" })
    public async componentDidMount() {
        let settings = await this.api.settings_read("AttrattoriTuristici", "Eventi", "Prodotti", "Sentieri", "BusItalia", "Meteo", "MeteoSecret", "MeteoApiKey",
            "Segnalazioni", "SegnalazioniSecret", "SegnalazioniSecret");
        this.setState({
            settings: settings,
            settingHash: FoUtility.hash(settings.filter(s => s.key == "AttrattoriTuristici" || s.key == "Eventi" || s.key == "Prodotti" || s.key == "Sentieri" || s.key == "OpenAPIUmbria")),
            meteoHash: FoUtility.hash(settings.filter(s => s.key == "Meteo" || s.key == "MeteoSecret" || s.key == "MeteoApiKey")),
            segnalazioniHash: FoUtility.hash(settings.filter(s => s.key == "Segnalazioni" || s.key == "SegnalazioniUsername" || s.key == "SegnalazioniPassword" || s.key == "SegnalazioniAlias" || s.key == "SegnalazioniSoftware"))
        });
    }

    public render() {
        return <>
            <FoPage>
                <FoSpinner show={FoLoaderService.hasLoading("settings", "settings_url")}>
                    <FoHeading title='Sistemi Esterni' subtitle="Imposta le Url da dove l'applicazione andrà a recuperare i dati da visualizzare all'utente">
                        <div className="flex space-x-2">
                            <button className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none fo-button fo-button-fill fo-primary" onClick={() => this.saveUrl()}
                                disabled={FoUtility.hash(this.state.settings?.filter(s => s.key == "AttrattoriTuristici" || s.key == "Eventi"
                                    || s.key == "Prodotti" || s.key == "Sentieri" || s.key == "OpenAPIUmbria")) == this.state.settingHash}>
                                Salva
                            </button>
                        </div>
                    </FoHeading>
                    <div className='space-y-4 mt-4'>
                        <Detail name="Url Open API Umbria" description="Link all'Open API dell'umbria" inline={true}>
                            <FoField className='pl-8 w-[650px]'>
                                <input type="text" className={`fo-input-no-default`}
                                    onChange={(evt: any) => { this.set("OpenAPIUmbria", evt.target.value) }}
                                    value={this.get("OpenAPIUmbria") ?? ""} />
                            </FoField>
                        </Detail>
                        <Detail name="Nome Package Attrazioni" description="Nome Package del package open data per le attrazioni turistiche" inline={true}>
                            <FoField className='pl-8 w-[650px]'>
                                <input type="text" className={`fo-input-no-default`}
                                    onChange={(evt: any) => { this.set("AttrattoriTuristici", evt.target.value) }}
                                    value={this.get("AttrattoriTuristici") ?? ""} />
                            </FoField>
                        </Detail>
                        <Detail name="Nome Package Eventi" description="Nome Package del package open data per gli eventi umbri" inline={true}>
                            <FoField className='pl-8 w-[650px]'>
                                <input type="text" className={`fo-input-no-default`}
                                    onChange={(evt: any) => { this.set("Eventi", evt.target.value) }}
                                    value={this.get("Eventi") ?? ""} />
                            </FoField>
                        </Detail>
                        <Detail name="Nome Package Prodotti" description="Nome Package del package open data dei prodotti tipici" inline={true}>
                            <FoField className='pl-8 w-[650px]'>
                                <input type="text" className={`fo-input-no-default`}
                                    onChange={(evt: any) => { this.set("Prodotti", evt.target.value) }}
                                    value={this.get("Prodotti") ?? ""} />
                            </FoField>
                        </Detail>
                        <Detail name="Nome Package Sentieri" description="Nome Package del package open data per il recupero dei sentieri" inline={true}>
                            <FoField className='pl-8 w-[650px]'>
                                <input type="text" className={`fo-input-no-default`}
                                    onChange={(evt: any) => { this.set("Sentieri", evt.target.value) }}
                                    value={this.get("Sentieri") ?? ""} />
                            </FoField>
                        </Detail>
                    </div>
                </FoSpinner>
                <FoSpinner show={FoLoaderService.hasLoading("settings", "settings_weather")}>
                    <FoHeading title='Meteo' subtitle="Configura le impostazioni per collegarsi al sistema WeatherLink" className="mt-12">
                        <div className="flex space-x-2">
                            <button className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none fo-button fo-button-fill fo-primary" onClick={() => this.saveMeteo()}
                                disabled={FoUtility.hash(this.state.settings?.filter(s => s.key == "Meteo" || s.key == "MeteoSecret"
                                    || s.key == "MeteoApiKey")) == this.state.meteoHash}>
                                Salva
                            </button>
                        </div>
                    </FoHeading>
                    <div className='space-y-4 mt-4'>
                        <Detail name="Url Meteo API" description="Url per invocare il servizio WeatherLink per recuperare i dati Meteo" inline={true}>
                            <FoField className='pl-8 w-[650px]'>
                                <input type="text" className={`fo-input-no-default`}
                                    onChange={(evt: any) => { this.set("Meteo", evt.target.value) }}
                                    value={this.get("Meteo") ?? ""} />
                            </FoField>
                        </Detail>
                        <Detail name="Secret" description="Secret WeatherLink (visualizzabile nel profilo utente)" inline={true}>
                            <FoField className='pl-8 w-[650px]'>
                                <input type="text" className={`fo-input-no-default`}
                                    onChange={(evt: any) => { this.set("MeteoSecret", evt.target.value) }}
                                    value={this.get("MeteoSecret") ?? ""} />
                            </FoField>
                        </Detail>
                        <Detail name="API Key" description="API Key WeatherLink (visualizzabile nel profilo utente)" inline={true}>
                            <FoField className='pl-8 w-[650px]'>
                                <input type="text" className={`fo-input-no-default`}
                                    onChange={(evt: any) => { this.set("MeteoApiKey", evt.target.value) }}
                                    value={this.get("MeteoApiKey") ?? ""} />
                            </FoField>
                        </Detail>
                    </div>
                </FoSpinner>
                <FoSpinner show={FoLoaderService.hasLoading("settings", "settings_segnalazioni")}>
                    <FoHeading title='Segnalazioni' subtitle="Configura le impostazioni per collegarsi al sistema delle Segnalazioni" className="mt-12">
                        <div className="flex space-x-2">
                            <button className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none fo-button fo-button-fill fo-primary" onClick={() => this.saveSegnalazioni()}
                                disabled={FoUtility.hash(this.state.settings?.filter(s => s.key == "Segnalazioni" || s.key == "SegnalazioniUsername"
                                    || s.key == "SegnalazioniPassword" || s.key == "SegnalazioniAlias" || s.key == "SegnalazioniSoftware")) == this.state.segnalazioniHash}>
                                Salva
                            </button>
                        </div>
                    </FoHeading>
                    <div className='space-y-4 mt-4'>
                        <Detail name="Url Segnalazioni API" description="Url per invocare il servizio delle Segnalazioni" inline={true}>
                            <FoField className='pl-8 w-[650px]'>
                                <input type="text" className={`fo-input-no-default`}
                                    onChange={(evt: any) => { this.set("Segnalazioni", evt.target.value) }}
                                    value={this.get("Segnalazioni") ?? ""} />
                            </FoField>
                        </Detail>
                        <Detail name="Username" description="Username del servizio di Segnalazioni" inline={true}>
                            <FoField className='pl-8 w-[650px]'>
                                <input type="text" className={`fo-input-no-default`}
                                    onChange={(evt: any) => { this.set("SegnalazioniUsername", evt.target.value) }}
                                    value={this.get("SegnalazioniUsername") ?? ""} />
                            </FoField>
                        </Detail>
                        <Detail name="Password" description="Password del servizio di Segnalazioni" inline={true}>
                            <FoField className='pl-8 w-[650px]'>
                                <input type="text" className={`fo-input-no-default`}
                                    onChange={(evt: any) => { this.set("SegnalazioniPassword", evt.target.value) }}
                                    value={this.get("SegnalazioniPassword") ?? ""} />
                            </FoField>
                        </Detail>
                        <Detail name="Alias" description="Alias del servizio di Segnalazioni" inline={true}>
                            <FoField className='pl-8 w-[650px]'>
                                <input type="text" className={`fo-input-no-default`}
                                    onChange={(evt: any) => { this.set("SegnalazioniAlias", evt.target.value) }}
                                    value={this.get("SegnalazioniAlias") ?? ""} />
                            </FoField>
                        </Detail>
                        <Detail name="Software" description="Software del servizio di Segnalazioni" inline={true}>
                            <FoField className='pl-8 w-[650px]'>
                                <input type="text" className={`fo-input-no-default`}
                                    onChange={(evt: any) => { this.set("SegnalazioniSoftware", evt.target.value) }}
                                    value={this.get("SegnalazioniSoftware") ?? ""} />
                            </FoField>
                        </Detail>
                    </div>
                </FoSpinner>
            </FoPage>
        </>;
    }

    @FoConfirm({ message: () => "Sicuro di voler modificare le url dei dati?" })
    @FoTryCatch({ message: () => "Errore durante il salvataggio delle configurazioni", onSuccess: () => FoNotifyService.success("Url modificate con successo") })
    @FoLoading("settings_url")
    public async saveUrl() {
        await this.api.settings_update(this.state.settings.filter(s => s.key == "AttrattoriTuristici" || s.key == "Eventi" || s.key == "Prodotti" || s.key == "Sentieri" || s.key == "OpenAPIUmbria"));
    }

    @FoConfirm({ message: () => "Sicuro di voler modificare le informazioni di WeatherLink?" })
    @FoTryCatch({ message: () => "Errore durante il salvataggio delle configurazioni", onSuccess: () => FoNotifyService.success("Informazioni WeatherLink modificate con successo") })
    @FoLoading("settings_weather")
    public async saveMeteo() {
        await this.api.settings_update(this.state.settings.filter(s => s.key == "Meteo" || s.key == "MeteoSecret" || s.key == "MeteoApiKey"));
    }

    @FoConfirm({ message: () => "Sicuro di voler modificare le informazioni sulle Segnalazioni?" })
    @FoTryCatch({ message: () => "Errore durante il salvataggio delle configurazioni", onSuccess: () => FoNotifyService.success("Informazioni delle Segnlazioni modificate con successo") })
    @FoLoading("settings_segnalazioni")
    public async saveSegnalazioni() {
        await this.api.settings_update(this.state.settings.filter(s => s.key == "Segnalazioni" || s.key == "SegnalazioniUsername"
            || s.key == "SegnalazioniPassword" || s.key == "SegnalazioniAlias" || s.key == "SegnalazioniSoftware"));
    }
}
