import { FoFile } from "../framework/components/inputs/file";
import { FoLoading } from "../framework/decorator/loading.decorator";
import { FoveaHttpRequest } from "../framework/http";
import { Attraction } from "./model/attraction";
import { AttractionPaginateResult } from "./model/attractionPaginateResult";
import { AttractionSpecification } from "./model/attractionSpecification";
import { Comune } from "./model/comune";
import { ConfigurationDto } from "./model/configurationDto";
import { Event } from "./model/event";
import { EventPaginateResult } from "./model/eventPaginateResult";
import { EventSpecification } from "./model/eventSpecification";
import { Job } from "./model/job";
import { Message } from "./model/message";
import { MessagePaginateResult } from "./model/messagePaginateResult";
import { MessageSpecification } from "./model/messageSpecification";
import { MeteoAggregateDto } from "./model/meteoAggregateDto";
import { Product } from "./model/product";
import { ProductPaginateResult } from "./model/productPaginateResult";
import { ProductSpecification } from "./model/productSpecification";
import { Sentiero } from "./model/sentiero";
import { SentieroPaginateResult } from "./model/sentieroPaginateResult";
import { StationDetails } from "./model/stationDetails";
import { Taxi } from "./model/taxi";
import { User } from "./model/user";
import { Warning } from "./model/warning";
import { WarningPaginateResult } from "./model/warningPaginateResult";
import { WarningSpecification } from "./model/warningSpecification";

export class UmbriaService extends FoveaHttpRequest {
    private url: string = process.env.REACT_APP_API_URL!;
    private version: string = "v1/";
    
    public async attraction_search(specification?: AttractionSpecification, page?: number, size?: number): Promise<AttractionPaginateResult> {
        return await this.post<AttractionPaginateResult>(this.url + this.version + "attraction/search/" + (page ?? 1) + "/" + (size ?? 10), specification ?? {}) ?? new AttractionPaginateResult();
    }

    public async attraction_create(entity: Attraction): Promise<Attraction> {
        return await this.post<Attraction>(this.url + this.version + "attraction", entity) ?? new Attraction();
    }

    public async attraction_update(entity: Attraction): Promise<Attraction> {
        return await this.put<Attraction>(this.url + this.version + "attraction/" + entity.id, entity) ?? new Attraction();
    }

    public async attraction_delete(entity: Attraction): Promise<Attraction> {
        return await this.delete<Attraction>(this.url + this.version + "attraction/" + entity.id) ?? new Attraction();
    }

    public async attraction_resume(entity: Attraction): Promise<Attraction> {
        return await this.patch<Attraction>(this.url + this.version + "attraction/" + entity.id + "/resume", null) ?? new Attraction();
    }

    public async attraction_read(id: any): Promise<Attraction> {
        return await this.get<Attraction>(this.url + this.version + "attraction/" + id) ?? new Attraction();
    }

    public async event_search(specification?: EventSpecification, page?: number, size?: number): Promise<EventPaginateResult> {
        return await this.post<EventPaginateResult>(this.url + this.version + "event/search/" + (page ?? 1) + "/" + (size ?? 10), specification ?? {}) ?? new EventPaginateResult();
    }

    public async event_create(entity: Event): Promise<Event> {
        return await this.post<Event>(this.url + this.version + "event", entity) ?? new Event();
    }

    public async event_update(entity: Event): Promise<Event> {
        return await this.put<Event>(this.url + this.version + "event/" + entity.id, entity) ?? new Event();
    }

    public async event_delete(entity: Event): Promise<Event> {
        return await this.delete<Event>(this.url + this.version + "event/" + entity.id) ?? new Event();
    }

    public async event_resume(entity: Event): Promise<Event> {
        return await this.patch<Event>(this.url + this.version + "event/" + entity.id + "/resume", null) ?? new Event();
    }

    public async event_read(id: any): Promise<Event> {
        return await this.get<Event>(this.url + this.version + "event/" + id) ?? new Event();
    }

    public async product_search(specification?: ProductSpecification, page?: number, size?: number): Promise<ProductPaginateResult> {
        return await this.post<ProductPaginateResult>(this.url + this.version + "product/search/" + (page ?? 1) + "/" + (size ?? 10), specification ?? {}) ?? new ProductPaginateResult();
    }

    public async product_create(entity: Product): Promise<Product> {
        return await this.post<Product>(this.url + this.version + "product", entity) ?? new Product();
    }

    public async product_update(entity: Product): Promise<Product> {
        return await this.put<Product>(this.url + this.version + "product/" + entity.id, entity) ?? new Product();
    }

    public async product_delete(entity: Product): Promise<Product> {
        return await this.delete<Product>(this.url + this.version + "product/" + entity.id) ?? new Product();
    }

    public async product_resume(entity: Product): Promise<Product> {
        return await this.patch<Product>(this.url + this.version + "product/" + entity.id + "/resume", null) ?? new Product();
    }

    public async product_read(id: any): Promise<Product> {
        return await this.get<Product>(this.url + this.version + "product/" + id) ?? new Product();
    }

    // public async sentieri_download(name: string): Promise<Product> {
    //     return await this.get<Product>(this.url + this.version + "product/" + id) ?? new Product();
    // }

    public async sentiero_create(entity: Sentiero): Promise<Sentiero> {
        return await this.post<Sentiero>(this.url + this.version + "sentieri", entity) ?? new Sentiero();
    }

    public async sentiero_update(entity: Sentiero): Promise<Sentiero> {
        return await this.put<Sentiero>(this.url + this.version + "sentieri/" + entity.id, entity) ?? new Sentiero();
    }

    public async sentiero_read(id: any): Promise<Sentiero> {
        return await this.get<Sentiero>(this.url + this.version + "sentieri/" + id) ?? new Sentiero();
    }

    public async sentiero_search(page?: number, size?: number): Promise<SentieroPaginateResult> {
        return await this.post<SentieroPaginateResult>(this.url + this.version + "sentieri/search/" + (page ?? 1) + "/" + (size ?? 10), {}) ?? new SentieroPaginateResult();
    }

    public async sentiero_delete(entity: Sentiero): Promise<Sentiero> {
        return await this.delete<Sentiero>(this.url + this.version + "sentieri/" + entity.id) ?? new Sentiero();
    }

    public async sentiero_resume(entity: Sentiero): Promise<Sentiero> {
        return await this.patch<Sentiero>(this.url + this.version + "sentieri/" + entity.id + "/resume", null) ?? entity;
    }

    public async sentiero_replace_routes(entity: Sentiero, file: FoFile): Promise<Sentiero> {
        let formData = new FormData();
        formData.append("file", file.content);
        return await this.patch<Sentiero>(this.url + this.version + "sentieri/" + entity.id + "/routes", formData) ?? entity;
    }

    public async warning_search(specification?: WarningSpecification, page?: number, size?: number): Promise<WarningPaginateResult> {
        return await this.post<WarningPaginateResult>(this.url + this.version + "warning/search/" + (page ?? 1) + "/" + (size ?? 10), specification ?? {}) ?? new WarningPaginateResult();
    }

    public async warning_create(entity: Warning): Promise<Warning> {
        return await this.post<Warning>(this.url + this.version + "warning", entity) ?? new Warning();
    }

    // public async warning_update(entity: Warning): Promise<Warning> {
    //     return await this.put<Warning>(this.url + this.version + "warning/" + entity.id, entity) ?? new Warning();
    // }

    // public async warning_delete(entity: Warning): Promise<Warning> {
    //     return await this.delete<Warning>(this.url + this.version + "warning/" + entity.id) ?? new Warning();
    // }

    public async warning_read(id: any): Promise<Warning> {
        return await this.get<Warning>(this.url + this.version + "warning/" + id) ?? new Warning();
    }

    public async admin_login(user: User): Promise<boolean> {
        return await this.post<boolean>(this.url + this.version + "admin/login", user) ?? false;
    }

    public async admin_get_job(name: string): Promise<Job> {
        return await this.get(this.url + this.version + "admin/job/" + name) ?? { name: name, totalItems: 0, addedItems: 0, updatedItems: 0, errorItems: 0, running: false } as Job;
    }

    public async admin_start_attrazioni_job(): Promise<void> {
        await this.post(this.url + this.version + "admin/job/attrattori-turistici", null);
    }

    public async admin_start_eventi(): Promise<void> {
        await this.post(this.url + this.version + "admin/job/eventi", null);
    }

    public async admin_start_prodotti(): Promise<void> {
        await this.post(this.url + this.version + "admin/job/prodotti-agro-alimentari", null);
    }

    public async admin_start_sentieri(): Promise<void> {
        await this.post(this.url + this.version + "admin/job/sentieri", null);
    }

    public async admin_start_bus_italia(file: FoFile): Promise<void> {
        let formData = new FormData();
        formData.append("file", file.content);
        await this.post(this.url + this.version + "admin/job/bus-italia", formData);
    }

    public async admin_read_comuni(): Promise<Comune[]> {
        return await this.get<Comune[]>(this.url + this.version + "admin/anagrafe/comuni") ?? [];
    }

    public async meteo_calculate(): Promise<MeteoAggregateDto[]> {
        return await this.get<MeteoAggregateDto[]>(this.url + this.version + "meteo/station/calculate") ?? [];
    }

    public async meteo_read_station_detail(id: number): Promise<StationDetails> {
        return await this.get<StationDetails>(this.url + this.version + "meteo/station/details/" + id) ?? new StationDetails();
    }

    public async meteo_write_station_detail(id: number, details: StationDetails): Promise<StationDetails> {
        return await this.put<StationDetails>(this.url + this.version + "meteo/station/details/" + id, details) ?? new StationDetails();
    }

    public async settings_read(... keys: string[]): Promise<ConfigurationDto[]> {
        return await this.get<ConfigurationDto[]>(this.url + this.version + "settings?" + keys.map(k => "key=" + k).join("&")) ?? [];
    }

    public async settings_update(configurations: ConfigurationDto[]): Promise<void> {
        await this.put(this.url + this.version + "settings", configurations);
    }

    public async taxi_read(): Promise<Taxi[]> {
        return await this.get<Taxi[]>(this.url + this.version + "mobilita/taxi") ?? [];
    }

    public async taxi_create(entity: Taxi): Promise<Taxi> {
        return await this.post<Taxi>(this.url + this.version + "mobilita/taxi", entity) ?? new Taxi();
    }

    public async taxi_update(entity: Taxi): Promise<Taxi> {
        return await this.put<Taxi>(this.url + this.version + "mobilita/taxi/" + entity.id, entity) ?? new Taxi();
    }

    public async taxi_delete(entity: Taxi): Promise<Taxi> {
        return await this.delete<Taxi>(this.url + this.version + "mobilita/taxi/" + entity.id) ?? new Taxi();
    }

    public async message_search(specification?: MessageSpecification, page?: number, size?: number): Promise<MessagePaginateResult> {
        return await this.post<MessagePaginateResult>(this.url + this.version + "message/search/" + (page ?? 1) + "/" + (size ?? 10), specification ?? {}) ?? new MessagePaginateResult();
    }

    public async message_create(entity: Message): Promise<Message> {
        return await this.post<Message>(this.url + this.version + "message", entity) ?? new Message();
    }

    public async message_update(entity: Message): Promise<Message> {
        return await this.put<Message>(this.url + this.version + "message/" + entity.id, entity) ?? new Message();
    }

    public async message_delete(entity: Message): Promise<Message> {
        return await this.delete<Message>(this.url + this.version + "message/" + entity.id) ?? new Message();
    }

    public async message_read(id: any): Promise<Message> {
        return await this.get<Message>(this.url + this.version + "message/" + id) ?? new Message();
    }
}