import React, { } from "react";
import { FoChildrenProps } from "../props";
import { FoAction, FoUtility } from "../core";

export class FoNotifyService extends React.Component<FoChildrenProps, { show: boolean, notification: { id: number, show: boolean, instance: React.ReactNode | any }[] }> {
    public async componentDidMount() {
        this.setState({ show: false, notification: [] });
        FoNotifyService.service = this;
    }

    public render() {
        return <>
            <div aria-live="assertive" className="z-20 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">

                    {this.state?.notification?.map((notify: { id: number, show: boolean, instance: React.ReactNode | any }) => (
                        <div key={FoUtility.hash(notify)} className={`pointer-events-auto w-full max-w-sm rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 
                            transition ease-in duration-200 translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2
                            ${notify.show ? "translate-y-0 opacity-100 sm:translate-x-0" : ""}`}>
                            {notify.instance}
                        </div>
                    ))}

                </div>
            </div>
            {this.props.children}
        </>;
    }

    private static service: FoNotifyService;
    public static show(notify: (close: (value?: any) => void) => React.ReactNode | any, lifetime: number = 5000): Promise<any> {
        let id = FoUtility.hash(new Date());
        return new Promise<any>((res) => {
            let instance = notify(res);
            FoNotifyService.service.state.notification.push({ id: id, show: false, instance: instance });
            FoNotifyService.service.setState({ notification: FoNotifyService.service.state.notification }, () => {
                let notify = FoNotifyService.service.state.notification.find(m => m.id == id);
                if(notify) { notify.show = true; }
                
                setTimeout(() => {
                    FoNotifyService.service.setState({ notification: FoNotifyService.service.state.notification });
                    if(lifetime != 0) { 
                        setTimeout(() => {
                            FoNotifyService.hide(id);
                        }, lifetime); 
                    }
                }, 1);
            });
        }).finally(() => {
            let notify = FoNotifyService.service.state.notification.find(m => m.id == id);
            if(notify && notify.show) { FoNotifyService.hide(id); }
        });
    }

    private static hide(id: number) {
        let notify = FoNotifyService.service.state.notification.find(m => m.id == id);
        if(notify) { notify.show = false; }

        FoNotifyService.service.setState({ notification: FoNotifyService.service.state.notification }, () => {
            let notifications = FoNotifyService.service.state.notification.filter(m => m.id != id);
            setTimeout(() => FoNotifyService.service.setState({ show: notifications.length > 0 }, () => {
                setTimeout(() => FoNotifyService.service.setState({ notification: notifications }), 100);
            }), 100);
        });
    }

    public static async notify(
            message: string, 
            title?: string,
            opts?: {
                icon?: React.ReactNode | any,
                actions?: FoAction[],
                closable?: boolean,
                lifetime?: number
            }
        ): Promise<void> {
        return await FoNotifyService.show((close) => {
            return <>
                <div className="p-4">
                    <div className="flex items-center">
                        {opts?.icon && <div className="flex-shrink-0 pt-0.5">
                            {opts?.icon}
                        </div>}
                        <div className="ml-3 w-0 flex-1">
                            {title && <h6>{title}</h6>}
                            <span>{message}</span>
                            {opts?.actions && <div className="mt-4 flex">
                                {opts?.actions.map(a => <button key={FoUtility.hash(a)} type="button" className={`mr-3 first:mr-0 inline-flex fo-button fo-outline first:fo-solid ${a.class}`} onClick={() => a.action(close)}>{a.text}</button>)}
                            </div>}
                        </div>
                        {(opts?.closable == undefined || opts?.closable) && <button className="fo-button fo-flat" onClick={() => close(null)}>
                            <span className="sr-only">Close</span>
                            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                            </svg>
                        </button>}
                    </div>
                </div>
            </>
        }, opts?.closable ? 0 : (opts?.lifetime ? opts?.lifetime : 5000));
    }

    public static async success(message: string = "Operazione avvenuta con successo", title?: string, lifetime?: number): Promise<void> {
        return await FoNotifyService.notify(message, title, {
            icon: <svg className="h-6 w-6 text-success-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>,
            lifetime: lifetime
          });
    }

    public static async warning(message: string, title?: string, lifetime?: number): Promise<void> {
        return await FoNotifyService.notify(message, title, {
            icon: <svg className="h-6 w-6 text-warning-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
            </svg>, 
            lifetime: lifetime
        });
    }

    public static async error(message: string = "Errore durante l'operazione", title?: string, lifetime?: number): Promise<void> {
        return await FoNotifyService.notify(message, title, {
            icon: <svg className="h-6 w-6 text-danger-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
            </svg>, 
            lifetime: lifetime
          });
    }
}