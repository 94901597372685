import { FoUtility } from "../core";

export class FoLoaderService {

    private static loadings: string[] = [];
    public static addLoading(... loaders: string[]): void {
        loaders?.forEach(l => FoLoaderService.loadings.push(l));
    }
    public static removeLoading(... loaders: string[]) {
        loaders?.forEach(l => FoLoaderService.loadings = FoUtility.remove(FoLoaderService.loadings, l));
    }
    public static hasLoading(... loaders: string[]): boolean {
        return loaders ? loaders.reduce((prev, current) => FoLoaderService.loadings.indexOf(current) != -1 || prev, false) : false;
    }
}