import React from "react";
import { FoSize, FoState } from "./enum";

export class FoUtility {
    public static hash(obj: any): number {
        return obj == undefined ? 0 : JSON.stringify(obj).split("").reduce(function (a, b) { a = ((a << 5) - a) + b.charCodeAt(0); return a & a }, 0);
    }
    public static remove(array: any[], value: any): any[] {
        let index = array.indexOf(value);
        array.splice(index, 1);
        return array;
    };
    public static getChild(childrens: any, key?: string): any {
        if(key != null)
            return React.Children.toArray(childrens).find((child: any) => child.props["fo-content"] == key);
        return React.Children.toArray(childrens).filter((child: any) => child.props["fo-content"] == undefined);
    };
    public static isNullOrEmpty(value: string): boolean {
        return value == null || value.length == 0;
    }
    public static size(size?: FoSize): string {
        switch (size) {
            case FoSize.Extrasmall:
                return "fo-xs";
            case FoSize.Medium:
                return "fo-md";
            case FoSize.Large:
                return "fo-lg";
            case FoSize.Extralarge:
                return "fo-xl";
            default:
                return "fo-sm";
        }
    }
    public static state(state?: FoState): string {
        switch (state) {
            case FoState.Primary:
                return "fo-primary";
            case FoState.Success:
                return "fo-success";
            case FoState.Warning:
                return "fo-warning";
            case FoState.Danger:
                return "fo-danger";
            case FoState.Info:
                return "fo-info";
            default:
                return "fo-contrast";
        }
    }
}

export class FoAction {
    public text!: string;
    public icon?: string;
    public class?: string;
    public action!: (data?: any) => void;
}