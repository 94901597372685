import { useState } from "react";
import { FoAnimationProps, FoChildrenProps, FoStyleProps } from "../props";

export interface FoOpacityProps extends FoChildrenProps, FoStyleProps, FoAnimationProps {}

export function FoOpacity(props: FoOpacityProps) {
    const [show, setShow] = useState(props.show);
    const [start, setStart] = useState(false);

    if (props.show != show) { 
        if(props.show) {
            setShow(true);
            setTimeout(() => {
                setStart(true);
                props.onEnd?.();
            }, 1); 
            props.onStart?.();
        } else if(props.show != start) {
            setStart(false);
            setTimeout(() => {
                setShow(false);
                props.onEnd?.();
            }, 200); 
        }
    }

    return (
        <>
            {(props.show || show) && <div className={`${props.className} transform ease-in-out duration-200 ${start ? 'opacity-100' : 'opacity-0'}`}>
                {props.children}
            </div>}
        </>
    );
}