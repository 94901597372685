export interface EmptyElementsProps {
    title?: string;
    description?: string;
}

export default function EmptyElements(props: EmptyElementsProps) {
    return (
        <div className="text-center mt-10">
            <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661Z" />
            </svg>
            {props.title && <h3 className="mt-2 text-sm font-semibold text-gray-900">{props.title}</h3>}
            <p className="mt-1 text-sm text-gray-500">{props.description ?? 'Nessun elemento trovato'}</p>
        </div>
    )
}
