import { useRef, useState } from "react";
import useClickOutside from "../../hooks/click-outside";
import { FoField, FoFieldProps } from "./field";
import { FoOpacity } from "../../animations/opacity";

export interface FoDropDownProps extends FoFieldProps {
    source?: { text: string, value?: any, selected?: boolean }[];
    onItemChange?: (item: { text: string, value?: any, selected?: boolean }) => void;
    selectionTemplate?: (items: { text: string, value?: any, selected?: boolean }[]) => any;
    itemTemplate?: (item: { text: string, value?: any, selected?: boolean }) => any;
    multiselect?: boolean;
}

export function FoDropDown(props: FoDropDownProps) {
    const [open, setOpen] = useState(false);
    const componentRef = useRef(null);
    useClickOutside(componentRef, () => setOpen(false));

    let selected = () => {
        return props.source?.filter(i => i.selected) ?? [];
    }

    let onChange = (item: { text: string, value?: any, selected?: boolean }) => {
        if (props.onItemChange) { props.onItemChange(item); }
        if (!props.multiselect) { setOpen(false); }
    }

    let content = () => {
        return props.selectionTemplate ? props.selectionTemplate(selected()) : <span className="block truncate">{selected().map(i => i.text).join(", ")}</span>;
    }

    return (
        <div ref={componentRef} className="relative my-auto">
            <FoField {...props} onClick={() => setOpen(!open)}>
                <button type="button">
                    <div className="flex items-center mr-5">
                        {content()}
                    </div>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <svg className="h-5 w-5 text-contrast-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                        </svg>
                    </div>
                </button>
            </FoField>
            <FoOpacity show={open} className="z-10 absolute w-full">
                <ul className={`mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}>
                    {props.source && props.source.map(item =>
                        <li onClick={() => onChange(item)} className="relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-primary-600 hover:text-white" id="listbox-option-0" role="option">
                            {props.itemTemplate == null ? <p className="text-inherit">{item.text}</p> : props.itemTemplate(item)}
                        </li>)}
                </ul>
            </FoOpacity>
        </div>
    );
}
