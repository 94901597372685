import { Comune } from '../../../_core/model/comune';
import { Event } from '../../../_core/model/event';
import { EventPaginateResult } from '../../../_core/model/eventPaginateResult';
import { EventSpecification } from '../../../_core/model/eventSpecification';
import { GlobalizationText } from '../../../_core/model/globalizationText';
import EmptyElements from '../../../common/empty-elements';
import { FoDropDown } from '../../../framework/components/inputs/dropdown';
import FoTable, { FoTableColumn } from '../../../framework/components/table';
import { FoBackdrop } from '../../../framework/components/widgets/backdrop';
import { FoBadge } from '../../../framework/components/widgets/badge';
import { FoFlatButton, FoSolidButton } from '../../../framework/components/widgets/button';
import { FoDrawer } from '../../../framework/components/widgets/drawer';
import { FoFlatPagination } from '../../../framework/components/widgets/pagination';
import { FoSpinner } from '../../../framework/components/widgets/spinner';
import { FoTryCatch } from '../../../framework/decorator/error.decorator';
import { FoLoading } from '../../../framework/decorator/loading.decorator';
import { FoPositionXEnum, FoSize, FoState } from '../../../framework/enum';
import FoPage from '../../../framework/layout/page';
import { FoLoaderService } from '../../../framework/services/loader.service';
import { BasePage } from '../../page.base';

export class Events extends BasePage<any, {
    result: EventPaginateResult
    filter: EventSpecification
}> {
    private showFilter: boolean = false;
    private comuni: Comune[] = [];

    @FoLoading("events")
    @FoTryCatch({ message: () => "Errore durante il recupero delle eventi" })
    public async componentDidMount() {
        this.comuni = await this.api.admin_read_comuni();
        await this.search();
    }
    private getValue(values: GlobalizationText[] | undefined | null): string {
        try {
            values = values ?? [];
            let result = values.find(v => v.language == "it")?.value;
            return result ?? "";
        } catch { return ""; }
    }

    public render() {
        return <>
            <FoSpinner show={FoLoaderService.hasLoading("events")}>
                {FoLoaderService.hasLoading("events") && <FoBackdrop />}
                <FoPage>
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h4>Eventi</h4>
                            <p className="mt-2">Lista di tutte gli eventi dell'Umbria</p>
                        </div>
                        <div className="flex space-x-2">
                            <button onClick={() => { this.showFilter = true; this.setState({}) }} className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none fo-button fo-button-semifill fo-button-border fo-contrast">
                                <svg className='h-6 w-6' fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                </svg>
                            </button>
                            <button onClick={() => this.navigate("/events/0")} className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none fo-button fo-button-fill fo-primary">Aggiungi Evento</button>
                        </div>
                    </div>
                    <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <FoTable source={this.state.result?.items ?? []} onItemClick={(item) => this.navigate('events/' + item.id)}>
                                    <FoTableColumn text='Nome' field='name' xPosition={FoPositionXEnum.Left} width={35} template={(item: Event) => {
                                        return <div className="text-wrap pr-4 font-medium text-contrast-900">{this.getValue(item.name)}</div>
                                    }} />
                                    <FoTableColumn text="Municipio" field="municipality" width={15} template={(item: Event) => {
                                        return <span className="pl-4 text-wrap">{item.municipality}</span>
                                    }} />
                                    <FoTableColumn text="Inizio" field="start" width={20} template={(item: Event) => {
                                        return <span className="pl-4 text-wrap">{item.start ? new Intl.DateTimeFormat('it-IT', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                          }).format(new Date(item.start)) : "N.D."}</span>
                                    }} />
                                    <FoTableColumn text="Fine" field="end" width={20} template={(item: Event) => {
                                        return <span className="pl-4 text-wrap">{item.end ? new Intl.DateTimeFormat('it-IT', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                          }).format(new Date(item.end)) : "N.D."}</span>
                                    }} />
                                    <FoTableColumn text="Stato" field="deleted" width={10} template={(item: Event) => {
                                        return <FoBadge state={item.deleted ? FoState.Danger : FoState.Success}>{item.deleted ? 'Non Visibile' : 'Visibile'}</FoBadge>
                                    }} />
                                </FoTable>
                                {!(this.state.result?.total! > 0) && <EmptyElements description='Nessun evento trovato' />}
                                {this.state.result?.total! > 0 && <FoFlatPagination current={this.state.result?.page} total={this.state.result?.total} size={this.state.result?.size}
                                    onClick={(page) => { this.setState({ result: { ...this.state.result, page: page } }, async () => await this.search()); }} />}
                            </div>
                        </div>
                    </div>
                </FoPage>
            </FoSpinner>
            <FoDrawer className="pb-5 pt-6 space-y-6" title='Filtra Eventi' show={this.showFilter} onClose={(value) => { this.showFilter = false; this.setState({}); }}>
                {/* <FoInput label='Nome' onChange={(value: string) => this.setState({ filter: { ...this.state.filter, name: value }})} /> */}
                <FoDropDown label='Comuni' source={this.comuni.map(c => { return { text: c.name ?? "", value: c.name, selected: c.name == this.state.filter?.municipality } })}
                    onItemChange={(value: any) => this.setState({ filter: { ...this.state.filter, municipality: value.value } })} />
                <div className='space-x-2 flex items-center'>
                    {/* <h6>Stato</h6> */}
                    {/* <FoRadio name='state' checked={this.state.filter?.visible == true} onCheck={() => this.setState({ filter: { ...this.state.filter, visible: true } })}>
                        <FoBadge state={FoState.Success}>Visibile</FoBadge>
                    </FoRadio>
                    <FoRadio name='state' checked={this.state.filter?.visible == false} onCheck={() => this.setState({ filter: { ...this.state.filter, visible: false } })}>
                        <FoBadge state={FoState.Danger}>Non Visibile</FoBadge>
                    </FoRadio>
                    <FoRadio name='state' checked={this.state.filter?.visible == undefined} onCheck={() => this.setState({ filter: { ...this.state.filter, visible: null } })}>
                        <FoBadge state={FoState.Contrast}>Tutti</FoBadge>
                    </FoRadio> */}
                </div>
                <div fo-content="footer" className="w-full flex justify-end space-x-2">
                    <FoFlatButton state={FoState.Contrast} size={FoSize.Large} onClick={async () => this.setState({ filter: new EventSpecification() })}>Pulisci</FoFlatButton>
                    <FoSolidButton state={FoState.Primary} size={FoSize.Large} onClick={async () => { this.showFilter = false; await this.search(); }}>Applica</FoSolidButton>
                </div>
            </FoDrawer >
        </>;
    }

    @FoLoading("events")
    @FoTryCatch({ message: () => "Errore durante il recupero degli eventi" })
    private async search(): Promise<void> {
        this.setState({
            result: await this.api.event_search(this.state.filter ?? new EventSpecification(),
                this.state.result?.page ?? 1, this.state.result?.size ?? 5)
        });
    }
}
