import { FoField } from "../framework/components/inputs/field";
import { FoSize } from "../framework/enum";
import { FoValueProps } from "../framework/props";
import { FoModalService } from "../framework/services/modal.service";

export default function Imgview(props: FoValueProps) {
    return (
        <>
            {props.value && <span className="group h-48 relative rounded-lg border-2 border-dashed border-contrast-300 p-2 text-center hover:border-contrast-400">
                <button className='hidden group-hover:block absolute right-3 top-3' onClick={() => props.onChange?.(null)}>
                    <svg className="h-5 w-5 text-danger-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                    </svg>
                </button>
                <img src={props.value} alt="Immagine Principale" className="h-full" />
            </span>}
        </>
    )
}

export async function ImgViewModal(): Promise<string> {
    console.debug();
    let url = "";
    return await FoModalService.form((close) => {
        return <>
            <div>
                <svg className="mx-auto h-12 w-12 text-contrast-400" viewBox="0 0 24 24" fill="currentColor">
                    <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 0 1 2.25-2.25h16.5A2.25 2.25 0 0 1 22.5 6v12a2.25 2.25 0 0 1-2.25 2.25H3.75A2.25 2.25 0 0 1 1.5 18V6ZM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0 0 21 18v-1.94l-2.69-2.689a1.5 1.5 0 0 0-2.12 0l-.88.879.97.97a.75.75 0 1 1-1.06 1.06l-5.16-5.159a1.5 1.5 0 0 0-2.12 0L3 16.061Zm10.125-7.81a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z" clip-rule="evenodd" />
                </svg>
                <div className="mt-3 text-center sm:mt-5">
                    <h5>Inserire la Url dell'immagine</h5>
                    <div className="mt-2">
                        <FoField>
                            <input type="text" className="fo-input-no-default w-full" onChange={(evt) => url = evt.target.value} />
                        </FoField>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6">
                <button type="button" className="w-full fo-button fo-button-fill fo-primary" onClick={() => close(url)}>Aggiungi</button>
            </div>
        </>
    }, { closable: true, size: FoSize.Small });
}
