import { EventSpecification } from '../../_core/model/eventSpecification';
import { Product } from '../../_core/model/product';
import { Warning } from '../../_core/model/warning';
import { WarningPaginateResult } from '../../_core/model/warningPaginateResult';
import { WarningSpecification } from '../../_core/model/warningSpecification';
import EmptyElements from '../../common/empty-elements';
import FoTable, { FoTableColumn } from '../../framework/components/table';
import { FoBackdrop } from '../../framework/components/widgets/backdrop';
import { FoFlatButton, FoSolidButton } from '../../framework/components/widgets/button';
import { FoDrawer } from '../../framework/components/widgets/drawer';
import { FoFlatPagination } from '../../framework/components/widgets/pagination';
import { FoSpinner } from '../../framework/components/widgets/spinner';
import { FoTryCatch } from '../../framework/decorator/error.decorator';
import { FoLoading } from '../../framework/decorator/loading.decorator';
import { FoPositionXEnum, FoSize, FoState } from '../../framework/enum';
import FoPage from '../../framework/layout/page';
import { FoLoaderService } from '../../framework/services/loader.service';
import { BasePage } from '../page.base';

export class Warnings extends BasePage<any, {
    result: WarningPaginateResult
    filter: WarningSpecification
}> {
    private showFilter: boolean = false;

    @FoLoading("warnings")
    @FoTryCatch({ message: () => "Errore durante il recupero delle segnalazioni" })
    public async componentDidMount() {
        await this.search();
    }

    public render() {
        return <>
            <FoSpinner show={FoLoaderService.hasLoading("warnings")}>
                {FoLoaderService.hasLoading("warnings") && <FoBackdrop />}
                <FoPage>
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h4>Segnalazioni</h4>
                            <p className="mt-2">Dalla lista è possibile visualizzare le segnalazioni inviate dagli utenti</p>
                        </div>
                        <div className="flex space-x-2">
                            <button onClick={() => { this.showFilter = true; this.setState({}) }} className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none fo-button fo-button-semifill fo-button-border fo-contrast">
                                <svg className='h-6 w-6' fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                {/* <FoTable source={this.state.result?.items ?? []} onItemClick={(item) => this.navigate('warnings/' + item.id)}>
                                    <FoTableColumn text='Titolo' field='title' xPosition={FoPositionXEnum.Left} width={40} template={(item: Warning) => {
                                        return <span>{item.details?.title}</span>
                                    }} />
                                    <FoTableColumn text="Categoria" field="category" width={25} template={(item: Warning) => {
                                        return <span>{item.details?.category?.description}</span>
                                    }} />
                                    <FoTableColumn text="Data Creazione" field="created" width={25} template={(item: Warning) => {
                                        return <span>{new Intl.DateTimeFormat('it-IT', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        }).format(new Date(item.created!))}</span>
                                    }} />
                                    <FoTableColumn text="Allegati" field="attaches" width={10} template={(item: Warning) => {
                                        return <>{item.attaches && item.attaches.length > 0 && <span className="flex space-x-2 items-center justify-center">
                                            <svg className='h-5 w-5' viewBox="0 0 24 24" fill="currentColor">
                                                <path fill-rule="evenodd" d="M18.97 3.659a2.25 2.25 0 0 0-3.182 0l-10.94 10.94a3.75 3.75 0 1 0 5.304 5.303l7.693-7.693a.75.75 0 0 1 1.06 1.06l-7.693 7.693a5.25 5.25 0 1 1-7.424-7.424l10.939-10.94a3.75 3.75 0 1 1 5.303 5.304L9.097 18.835l-.008.008-.007.007-.002.002-.003.002A2.25 2.25 0 0 1 5.91 15.66l7.81-7.81a.75.75 0 0 1 1.061 1.06l-7.81 7.81a.75.75 0 0 0 1.054 1.068L18.97 6.84a2.25 2.25 0 0 0 0-3.182Z" clip-rule="evenodd" />
                                            </svg>
                                            <span>{item.attaches?.length}</span>
                                        </span>}</>
                                    }} />
                                </FoTable> */}
                                {!(this.state.result?.total! > 0) && <EmptyElements description='Nessuna segnalazione trovata' />}
                                {this.state.result?.total! > 0 && <FoFlatPagination current={this.state.result?.page} total={this.state.result?.total} size={this.state.result?.size}
                                    onClick={(page) => { this.setState({ result: { ...this.state.result, page: page } }, async () => await this.search()); }} />}
                            </div>
                        </div>
                    </div>
                </FoPage>
            </FoSpinner>
            <FoDrawer className="pb-5 pt-6 space-y-6" title='Filtra Segnalazioni' show={this.showFilter} onClose={(value) => { this.showFilter = false; this.setState({}); }}>
                {/* <FoInput label='Nome' onChange={(value: string) => this.setState({ filter: { ...this.state.filter, name: value }})} /> */}
                {/* <FoDropDown label='Comuni' source={this.comuni.map(c => { return { text: c, value: c, selected: c == this.state.filter?.municipality } })}
                    onChange={(value: any) => this.setState({ filter: { ...this.state.filter, municipality: value.value } })} /> */}
                <div className='space-x-2 flex items-center'>
                    <h6>Stato</h6>
                    {/* <FoRadio name='state' checked={this.state.filter?.visible == true} onCheck={() => this.setState({ filter: { ...this.state.filter, visible: true } })}>
                        <FoBadge state={FoState.Success}>Visibile</FoBadge>
                    </FoRadio>
                    <FoRadio name='state' checked={this.state.filter?.visible == false} onCheck={() => this.setState({ filter: { ...this.state.filter, visible: false } })}>
                        <FoBadge state={FoState.Danger}>Non Visibile</FoBadge>
                    </FoRadio>
                    <FoRadio name='state' checked={this.state.filter?.visible == undefined} onCheck={() => this.setState({ filter: { ...this.state.filter, visible: null } })}>
                        <FoBadge state={FoState.Contrast}>Tutti</FoBadge>
                    </FoRadio> */}
                </div>
                <div fo-content="footer" className="w-full flex justify-end space-x-2">
                    <FoFlatButton state={FoState.Contrast} size={FoSize.Large} onClick={async () => this.setState({ filter: new WarningSpecification() })}>Pulisci</FoFlatButton>
                    <FoSolidButton state={FoState.Primary} size={FoSize.Large} onClick={async () => { this.showFilter = false; await this.search(); }}>Applica</FoSolidButton>
                </div>
            </FoDrawer >
        </>;
    }

    @FoLoading("warnings")
    @FoTryCatch({ message: () => "Errore durante il recupero delle segnalazioni" })
    private async search(): Promise<void> {
        this.setState({
            result: await this.api.warning_search(this.state.filter ?? new WarningSpecification(),
                this.state.result?.page ?? 1, this.state.result?.size ?? 5)
        });
    }
}
