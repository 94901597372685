import { FoChildrenProps, FoStyleProps } from "../../props";

export interface FoBackDropProps extends FoChildrenProps, FoStyleProps {
    full?: boolean;
}

export function FoBackdrop(props: FoBackDropProps) {
    return (
        <>
            <div className={`${props.className} ${!props.full ? 'absolute' : 'fixed' } z-10 w-full h-full 
                bg-contrast-500 bg-opacity-45 ${!props.full ? 'bg-white' : 'bg-contrast-500'}`}>
                {props.children}
            </div>
        </>
    );
}
