/**
 * Umbria Mobile
 * Gestione dei dati della zona SSO Umbra
 *
 * The version of the OpenAPI document: v1.0
 * Contact: support@fovea.group
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { RequestFile } from './models';

export class StationDetails {
    'id'?: number;
    'hours1'?: number;
    'hours3'?: number;
    'hours6'?: number;
    'hours12'?: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "hours1",
            "baseName": "hours1",
            "type": "number"
        },
        {
            "name": "hours3",
            "baseName": "hours3",
            "type": "number"
        },
        {
            "name": "hours6",
            "baseName": "hours6",
            "type": "number"
        },
        {
            "name": "hours12",
            "baseName": "hours12",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return StationDetails.attributeTypeMap;
    }
}

