import { FoUtility } from "../../core";
import { FoSize, FoState } from "../../enum";
import { FoChildrenProps, FoStyleProps } from "../../props";

export interface FoButtonProps extends FoChildrenProps, FoStyleProps {
    state?: FoState;
    size?: FoSize;
    onClick?: () => void;
}

export function FoSolidButton(props: FoButtonProps) {
    return (
        <>
            <button type="button" className={`${props.className} fo-button fo-button-fill ${FoUtility.size(props.size)} ${FoUtility.state(props.state)}`} 
                onClick={() => props.onClick?.() } disabled={props.disabled}>{props.children}</button>
        </>
    );
}

export function FoFlatButton(props: FoButtonProps) {
    return (
        <>
            <button type="button" className={`${props.className} fo-button fo-button-semifill fo-button-border ${FoUtility.size(props.size)} ${FoUtility.state(props.state)}`} 
                onClick={() => props.onClick?.() } disabled={props.disabled}>{props.children}</button>
        </>
    );
}
