import { FoChildrenProps, FoStyleProps, FoValueProps } from "../../props";
import { FoPositionYEnum } from "../../enum";

export interface FoRadioProps extends FoStyleProps, FoValueProps, FoChildrenProps {
    name?: string;
    position?: FoPositionYEnum;
    checked?: boolean;
    onCheck?: () => void;
}

export function FoRadio(props: FoRadioProps) {
    return (
        <label htmlFor={props.id} className={`${props.className} relative flex space-x-2
            ${props.position == FoPositionYEnum.Top ? 'items-start' : (props.position == FoPositionYEnum.Bottom ? 'items-end' : 'items-center')}`}>
            <input type="radio" className={props.position == FoPositionYEnum.Top ? 'mt-0.5' : (props.position == FoPositionYEnum.Bottom ? 'mb-0.5' : '')} 
                id={props.id} name={props.name} value={props.value} onChange={(evt: any) => { 
                    props.onChange?.(evt.target.checked, evt);
                    if(evt.target.checked == true) { props.onCheck?.(); }
                }} checked={props.checked} />
            {typeof props.children == 'string' ? <h6>{props.children}</h6> : <>{props.children}</>}
        </label>
    );
}
