import { FoModalService } from "../services/modal.service";

export function FoConfirm(options?: {
        message?: (...args: any[]) => string,
        title?: (...args: any[]) => string
    }) {
    return function (target: any, key: string, descriptor: any) {
        const originalMethod = descriptor.value;
        descriptor.value = async function (...args: any[]) {
            let result = await FoModalService.confirm(options?.message?.(args), options?.title?.(args));
            if (result) { return await originalMethod.apply(this, args); }
        };
        return descriptor;
    };
}