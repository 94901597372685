import { FoUtility } from "./core";

//https://stackoverflow.com/questions/31273093/how-to-add-custom-html-attributes-in-jsx
export class FoForm {
    constructor(item: any, 
        fields: { 
            name: string,
            validations?: FoFormValidation<any>[] 
        }[], 
        refreshState?: (item: any) => void) {
        this.item = item ?? {};
        this.actual = FoUtility.hash(this.item);
        this.fields = Object.getOwnPropertyNames(this.item).map(field => { 
            return {
                name: field,
                validations: fields?.find(f => f.name == field)?.validations ?? [],
                initialValue: this.item[field],
                blur: false
            }});
        this.refresh = () => { if(refreshState) { refreshState(this.item); } };
        this.refresh();
    }
    private actual: number = -1;
    private item: any = {};
    private fields: { name: string, validations?: FoFormValidation<any>[], initialValue?: any, blur?: boolean }[] = [];
    private refresh: () => void;

    public get<T>(name: string, defaultValue?: T): T | undefined {
        return this.item && this.item[name] ? this.item[name] : defaultValue;
    }
    public set(name: string, value: any): void {
        this.item[name] = value;
        this.refresh();
    }
    public isValid(name: string): boolean {
        let field = this.fields.find(f => f.name == name);
        if(!field?.validations || field.validations.length == 0) { return true; }
        return !(field.validations.map(v => {
            return v.validate(this.item[name])
        }).indexOf(false) != -1);
    }
    public isChanged(name: string): boolean {
        let field = this.fields.find(f => f.name == name);
        return field?.initialValue != this.item[name];
    }
    public reset(name: string): void {
        let field = this.fields.find(f => f.name == name);
        this.item[name] = field?.initialValue;
        this.refresh();
    }

    public get changed(): boolean {
        return this.actual != FoUtility.hash(this.item);
    }
    public get valid(): boolean {
        let result = this.fields.map(f => f.name).reduce((prev, current) => prev && this.isValid(current), true);
        return result;
    }
}

export abstract class FoFormValidation<T> {
    abstract validate(value: T): boolean;
}
export class FoRequiredFormValidation extends FoFormValidation<any> {
    public validate(value: any): boolean {
        return value != null;
    }
    public static create(): FoRequiredFormValidation {
        return new FoRequiredFormValidation();
    }
}
export class FoEmailFormValidation extends FoFormValidation<string> {
    public validate(value: string): boolean {
        return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);
    }
    public static create(): FoEmailFormValidation {
        return new FoEmailFormValidation();
    }
}
export class FoCustomFormValidation extends FoFormValidation<any> {
    constructor(private validator: (value: any) => boolean) { super(); }

    public validate(value: any): boolean {
        return this.validator(value);
    }
    public static create(validator: (value: any) => boolean): FoCustomFormValidation {
        return new FoCustomFormValidation(validator);
    }
}