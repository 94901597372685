import { FoPositionXEnum } from "../enum";
import React from "react";
import { FoChildrenProps, FoStyleProps } from "../props";

export interface FoTableColumnProps extends FoStyleProps {
    text?: string;
    field?: string;
    width?: number;
    xPosition?: FoPositionXEnum;
    template?: (item: any, index: number, onChange?: (item?: any) => void) => any;
}

export function FoTableColumn(props: FoTableColumnProps) {
    return <></>;
}

export interface FoTableProps extends FoChildrenProps {
    source?: any[];
    onItemClick?: (item: any) => any;
}

export default function FoTable(props: FoTableProps) {

    const toString = (item: any, column: FoTableColumnProps) => {
        if (column.field == undefined) { return ""; }
        let value = item?.[column.field];
        if (value == null || value == "")
            return '-';
        return value;
    }

    return (
        <table className="min-w-full divide-y divide-gray-300">
            <thead>
                <tr>
                    {React.Children.toArray(props.children).map((c: any) => c.props as FoTableColumnProps).map(c => {
                        return <th style={{ width: c.width + "%" }}
                            scope="col" className={`first:pl-2 last:pr-2 py-3.5 ext-sm font-semibold text-gray-900 
                                ${c.xPosition == FoPositionXEnum.Left ? 'text-left' : (c.xPosition == FoPositionXEnum.Right ? 'text-right' : 'text-center')}`}>
                            <h5>{c.text}</h5>
                        </th>;
                    })}
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
                {props.source?.map((item: any, index: number) => {
                    return <tr className={props.onItemClick != null ? 'hover:bg-contrast-50 cursor-pointer' : ''} onClick={() => props.onItemClick?.(item)}>{React.Children.toArray(props.children).map((c: any) => c.props as FoTableColumnProps).map((c: FoTableColumnProps) => {
                        return <td style={{ width: c.width + "%" }} className={`${c.className} first:pl-2 last:pr-2 whitespace-nowrap py-5 text-sm sm:pl-0 
                            ${c.xPosition == FoPositionXEnum.Left ? 'text-left' : (c.xPosition == FoPositionXEnum.Right ? 'text-right' : 'text-center')}`}>
                            {c.template ? c.template(item, index, props.onChildrenChange) : <p>{toString(item, c)}</p>}
                        </td>;
                    })}</tr>;
                })}
            </tbody>
        </table>
    );
}
