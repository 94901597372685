import { AttractionSpecification } from "../../_core/model/attractionSpecification";
import { EventSpecification } from "../../_core/model/eventSpecification";
import { Job } from "../../_core/model/job";
import { ProductSpecification } from "../../_core/model/productSpecification";
import { FoFile, FoInputFile } from "../../framework/components/inputs/file";
import { FoBackdrop } from "../../framework/components/widgets/backdrop";
import { FoBadge } from "../../framework/components/widgets/badge";
import { FoBox } from "../../framework/components/widgets/box";
import { FoSolidButton } from "../../framework/components/widgets/button";
import { FoTryCatch } from "../../framework/decorator/error.decorator";
import { FoLoading } from "../../framework/decorator/loading.decorator";
import { FoConfirm } from "../../framework/decorator/modal.decorator";
import { FoSize, FoState } from "../../framework/enum";
import FoPage from "../../framework/layout/page";
import { FoNotifyService } from "../../framework/services/notify.service";
import { BasePage } from "../page.base";

export class Dashboard extends BasePage<any, {
    info: UmbriaBoxProps[],
    attrazioniJob: Job,
    eventiJob: Job,
    prodottiJob: Job,
    sentieriJob: Job,
    busJob: Job,
    busFile?: FoFile
}> {
    private jobs: UmbriaJobProps[] = [];

    @FoLoading("dashboard")
    @FoTryCatch({ message: () => "Errore durante il recupero delle informazioni" })
    public async componentDidMount() {
        if(sessionStorage.getItem("SOOUser") == null) { return; }
        await this.setStateAsync({
            attrazioniJob: await this.api.admin_get_job("Attrazioni"),
            eventiJob: await this.api.admin_get_job("Eventi"),
            prodottiJob: await this.api.admin_get_job("Prodotti"),
            sentieriJob: await this.api.admin_get_job("Sentieri"),
            busJob: await this.api.admin_get_job("BusItalia"),
            info: [{
                name: "Eventi",
                count: (await this.api.event_search(new EventSpecification())).total!,
                icon: <svg className="h-5 w-5 text-contrast-600 group-hover:text-primary-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
                    <path fill-rule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clip-rule="evenodd" />
                </svg>,
                state: FoState.Success
            }, {
                name: "Attrazioni",
                count: (await this.api.attraction_search(new AttractionSpecification())).total!,
                icon: <svg className="h-5 w-5 text-contrast-600 group-hover:text-primary-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path d="M11.584 2.376a.75.75 0 0 1 .832 0l9 6a.75.75 0 1 1-.832 1.248L12 3.901 3.416 9.624a.75.75 0 0 1-.832-1.248l9-6Z" />
                    <path fill-rule="evenodd" d="M20.25 10.332v9.918H21a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1 0-1.5h.75v-9.918a.75.75 0 0 1 .634-.74A49.109 49.109 0 0 1 12 9c2.59 0 5.134.202 7.616.592a.75.75 0 0 1 .634.74Zm-7.5 2.418a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Zm3-.75a.75.75 0 0 1 .75.75v6.75a.75.75 0 0 1-1.5 0v-6.75a.75.75 0 0 1 .75-.75ZM9 12.75a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Z" clip-rule="evenodd" />
                    <path d="M12 7.875a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z" />
                </svg>,
                state: FoState.Contrast
            }, {
                name: "Prodotti",
                count: (await this.api.product_search(new ProductSpecification())).total!,
                icon: <svg className="h-6 w-6 text-contrast-600 group-hover:text-primary-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path d="M5.625 3.75a2.625 2.625 0 1 0 0 5.25h12.75a2.625 2.625 0 0 0 0-5.25H5.625ZM3.75 11.25a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5H3.75ZM3 15.75a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75ZM3.75 18.75a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5H3.75Z" />
                </svg>,
                state: FoState.Info
            }, {
                name: "Sentieri",
                count: (await this.api.sentiero_search()).total!,
                icon: <svg className="h-6 w-6 text-contrast-600 group-hover:text-primary-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M15.75 4.5a3 3 0 1 1 .825 2.066l-8.421 4.679a3.002 3.002 0 0 1 0 1.51l8.421 4.679a3 3 0 1 1-.729 1.31l-8.421-4.678a3 3 0 1 1 0-4.132l8.421-4.679a3 3 0 0 1-.096-.755Z" clip-rule="evenodd" />
                </svg>,
                state: FoState.Danger
            }]
        });
        this.refreshJob();
    }

    public render() {
        return <FoPage className="space-y-4">
            <section className="grid md:grid-cols-2 xl:grid-cols-4 gap-6">
                {this.state.info?.map(i => <UmbriaBox name={i.name} count={i.count} icon={i.icon} state={i.state} />)}
            </section>
            <div className="space-y-4">
                {this.jobs?.map(job => <UmbriaJob name={job.name} icon={job.icon} state={job.state}
                    updated={job.updated} total={job.total} added={job.added} error={job.error} date={job.date} onClick={() => this.startJob(job)} />)}
                {this.state.busJob != null && <div className="relative">
                    {this.state.busJob.running == true && <FoBackdrop />}
                    <label className="relative block cursor-pointer text-left w-full">
                        <input type="radio" name="radio-buttons" className="peer sr-only" />
                        <div className="p-4 rounded dark:bg-contrast-800 border border-contrast-200 dark:border-contrast-700 hover:border-contrast-300 dark:hover:border-contrast-600 shadow-sm duration-150 ease-in-out">
                            <div className="grid grid-cols-12 items-center gap-x-2">
                                <div className="col-span-3 order-1 sm:order-none sm:col-span-3 flex items-center space-x-4 lg:sidebar-expanded:col-span-6 xl:sidebar-expanded:col-span-3">
                                    <svg className="h-6 w-6 text-contrast-600 group-hover:text-primary-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" clip-rule="evenodd" />
                                    </svg>
                                    <div>
                                        <div className="text-sm font-medium text-contrast-800 dark:text-contrast-100">{this.state.busJob.name}</div>
                                        <div className="text-xs">Ultima sincronizzazione: {this.state.busJob.execute ? new Intl.DateTimeFormat('it-IT', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        }).format(new Date(this.state.busJob.execute)) : "N.D."}</div>
                                    </div>
                                </div>
                                <div className="col-span-4 order-2 sm:order-none sm:col-span-4 text-left sm:text-center lg:sidebar-expanded:hidden xl:sidebar-expanded:block">
                                    <div className="text-sm font-medium text-contrast-800 dark:text-contrast-100 truncate">Caricare il file zip di Bus Italia</div>
                                </div>
                                <div className="col-span-2 order-2 sm:order-none sm:col-span-2 text-left sm:text-center lg:sidebar-expanded:hidden xl:sidebar-expanded:block">
                                    <FoInputFile accept=".zip" onUpload={(files) => this.setState({ busFile: files[0] })} />
                                </div>
                                <div className="col-span-2 order-1 sm:order-none sm:col-span-2 text-right sm:text-center lg:sidebar-expanded:col-span-6 xl:sidebar-expanded:col-span-4">
                                    <FoBadge state={this.state.busJob.running ? FoState.Success : FoState.Warning} size={FoSize.Small}>
                                        {this.state.busJob.running ? "Sincronizzazione in corso" : "In Attesa"}
                                    </FoBadge>
                                </div>
                                <div className="col-span-1 order-2 sm:order-none sm:col-span-1 text-right lg:sidebar-expanded:hidden xl:sidebar-expanded:block">
                                    <FoSolidButton size={FoSize.Extrasmall} state={FoState.Danger} onClick={() => this.uploadBusFile()} disabled={this.state.busJob.running == true || this.state.busFile == null}>Avvia</FoSolidButton>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>}
            </div>
        </FoPage>;
    }

    @FoConfirm({ message: () => "Sicuro di voler avviare la sincronizzazione? (Lanciare in orari di traffico più basso)" })
    @FoTryCatch({ onSuccess: (instance: any, args: any[]) => FoNotifyService.success("Sincronizzazione 'Bus Italia' andata a buon fine"), 
        message: (args: any[]) => "Sincronizzazione 'Bus Italia' non andata a buon fine"})
    private async uploadBusFile() {
        if(this.state.busFile) {
            let job = this.state.busJob;
            try {
                job.running = true;
                await this.setStateAsync({ busJob: job });
                await this.api.admin_start_bus_italia(this.state.busFile);
            } finally {
                job.running = false;
                await this.setStateAsync({ busJob: job });
            }
        }
    }

    @FoConfirm({ message: () => "Sicuro di voler avviare la sincronizzazione? (Lanciare in orari di traffico più basso)" })
    @FoTryCatch({ onSuccess: (instance: any, args: any[]) => FoNotifyService.success("Sincronizzazione '" + args[0].name + "' andata a buon fine"), 
        message: (args: any[]) => "Sincronizzazione '" + args[0].name + "' non andata a buon fine"})
    private async startJob(job: UmbriaJobProps) {
        try { 
            if (job.name == "Attrazioni") {
                this.state.attrazioniJob.running = true;
                await this.setState({});
                await this.refreshJob();
                await this.api.admin_start_attrazioni_job();
                this.state.info.find(i => i.name == job.name)!.count = (await this.api.attraction_search(new AttractionSpecification())).total!;
                await this.setStateAsync({ attrazioniJob: await this.api.admin_get_job(job.name) });
            }
            else if (job.name == "Eventi") {
                this.state.eventiJob.running = true;
                await this.setState({});
                await this.refreshJob();
                await this.api.admin_start_eventi();
                this.state.info.find(i => i.name == job.name)!.count = (await this.api.event_search(new EventSpecification())).total!;
                await this.setStateAsync({ eventiJob: await this.api.admin_get_job(job.name) });
            }
            else if (job.name == "Prodotti") {
                this.state.prodottiJob.running = true;
                await this.setState({});
                await this.refreshJob();
                await this.api.admin_start_prodotti();
                this.state.info.find(i => i.name == job.name)!.count = (await this.api.product_search(new ProductSpecification())).total!;
                await this.setStateAsync({ prodottiJob: await this.api.admin_get_job(job.name) });
            }
            else if (job.name == "Sentieri") {
                this.state.sentieriJob.running = true;
                await this.setState({});
                await this.refreshJob();
                await this.api.admin_start_sentieri();
                this.state.info.find(i => i.name == job.name)!.count = (await this.api.sentiero_search()).total!;
                await this.setStateAsync({ sentieriJob: await this.api.admin_get_job(job.name) });
            }
        } finally {
            let field = job.name?.toLowerCase() + "Job";
            (this.state as any)[field] = await this.api.admin_get_job(job.name!);
            await this.setState({});
            await this.refreshJob();
        }
    }

    private async refreshJob() {
        this.jobs = [];
        this.jobs.push({
            name: this.state.attrazioniJob?.name ?? "Attrazioni",
            icon: <svg className="h-5 w-5 text-contrast-600 group-hover:text-primary-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path d="M11.584 2.376a.75.75 0 0 1 .832 0l9 6a.75.75 0 1 1-.832 1.248L12 3.901 3.416 9.624a.75.75 0 0 1-.832-1.248l9-6Z" />
                <path fill-rule="evenodd" d="M20.25 10.332v9.918H21a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1 0-1.5h.75v-9.918a.75.75 0 0 1 .634-.74A49.109 49.109 0 0 1 12 9c2.59 0 5.134.202 7.616.592a.75.75 0 0 1 .634.74Zm-7.5 2.418a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Zm3-.75a.75.75 0 0 1 .75.75v6.75a.75.75 0 0 1-1.5 0v-6.75a.75.75 0 0 1 .75-.75ZM9 12.75a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Z" clip-rule="evenodd" />
                <path d="M12 7.875a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z" />
            </svg>,
            date: this.state.attrazioniJob?.execute,
            added: this.state.attrazioniJob?.addedItems ?? 0,
            updated: this.state.attrazioniJob?.updatedItems ?? 0,
            total: this.state.attrazioniJob?.totalItems ?? 0,
            error: this.state.attrazioniJob?.errorItems ?? 0,
            state: this.state.attrazioniJob?.running ?? false
        });
        this.jobs.push({
            name: this.state.eventiJob?.name ?? "Eventi",
            icon: <svg className="h-5 w-5 text-contrast-600 group-hover:text-primary-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
                <path fill-rule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clip-rule="evenodd" />
            </svg>,
            date: this.state.eventiJob?.execute,
            added: this.state.eventiJob?.addedItems ?? 0,
            updated: this.state.eventiJob?.updatedItems ?? 0,
            total: this.state.eventiJob?.totalItems ?? 0,
            error: this.state.eventiJob?.errorItems ?? 0,
            state: this.state.eventiJob?.running ?? false
        });
        this.jobs.push({
            name: this.state.prodottiJob?.name ?? "Prodotti",
            icon: <svg className="h-5 w-5 text-contrast-600 group-hover:text-primary-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path d="M5.625 3.75a2.625 2.625 0 1 0 0 5.25h12.75a2.625 2.625 0 0 0 0-5.25H5.625ZM3.75 11.25a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5H3.75ZM3 15.75a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75ZM3.75 18.75a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5H3.75Z" />
            </svg>,
            date: this.state.prodottiJob?.execute,
            added: this.state.prodottiJob?.addedItems ?? 0,
            updated: this.state.prodottiJob?.updatedItems ?? 0,
            total: this.state.prodottiJob?.totalItems ?? 0,
            error: this.state.prodottiJob?.errorItems ?? 0,
            state: this.state.prodottiJob?.running ?? false
        });
        this.jobs.push({
            name: this.state.sentieriJob?.name ?? "Sentieri",
            icon: <svg className="h-5 w-5 text-contrast-600 group-hover:text-primary-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M15.75 4.5a3 3 0 1 1 .825 2.066l-8.421 4.679a3.002 3.002 0 0 1 0 1.51l8.421 4.679a3 3 0 1 1-.729 1.31l-8.421-4.678a3 3 0 1 1 0-4.132l8.421-4.679a3 3 0 0 1-.096-.755Z" clip-rule="evenodd" />
            </svg>,
            date: this.state.sentieriJob?.execute,
            added: this.state.sentieriJob?.addedItems ?? 0,
            updated: this.state.sentieriJob?.updatedItems ?? 0,
            total: this.state.sentieriJob?.totalItems ?? 0,
            error: this.state.sentieriJob?.errorItems ?? 0,
            state: this.state.sentieriJob?.running ?? false
        });
        await this.setState({});
    }
}

interface UmbriaBoxProps {
    icon: any,
    count: number,
    name: string,
    state: FoState
}

function UmbriaBox(props: UmbriaBoxProps) {
    return (<div className="flex items-center p-8 bg-white shadow rounded-lg">
        <div className={`inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-${props.state.toString()}-600 bg-${props.state.toString()}-100 rounded-full mr-6`}>
            {props.icon}
        </div>
        <div>
            <span className="block text-2xl font-bold">{props.count}</span>
            <span className="block text-gray-500">{props.name}</span>
        </div>
    </div>)
}

interface UmbriaJobProps {
    icon: any;
    name?: string;
    date?: Date;
    total: number;
    added: number;
    updated: number;
    error: number;
    state: boolean;
    onClick?: () => void;
}

function UmbriaJob(props: UmbriaJobProps) {
    return (
        <div className="relative">
            {props.state == true && <FoBackdrop />}
            <label className="relative block cursor-pointer text-left w-full">
                <input type="radio" name="radio-buttons" className="peer sr-only" />
                <div className="p-4 rounded dark:bg-contrast-800 border border-contrast-200 dark:border-contrast-700 hover:border-contrast-300 dark:hover:border-contrast-600 shadow-sm duration-150 ease-in-out">
                    <div className="grid grid-cols-12 items-center gap-x-2">
                        <div className="col-span-3 order-1 sm:order-none sm:col-span-3 flex items-center space-x-4 lg:sidebar-expanded:col-span-6 xl:sidebar-expanded:col-span-3">
                            {props.icon}
                            <div>
                                <div className="text-sm font-medium text-contrast-800 dark:text-contrast-100">{props.name}</div>
                                <div className="text-xs">Ultima sincronizzazione: {props.date ? new Intl.DateTimeFormat('it-IT', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                }).format(new Date(props.date)) : "N.D."}</div>
                            </div>
                        </div>
                        <div className="col-span-3 order-2 sm:order-none sm:col-span-3 text-left sm:text-center lg:sidebar-expanded:hidden xl:sidebar-expanded:block">
                            <div className="text-sm font-medium text-contrast-800 dark:text-contrast-100 truncate">Elementi totali: {props.total} (In errore: {props.error})</div>
                        </div>
                        <div className="col-span-2 order-2 sm:order-none sm:col-span-2 text-left sm:text-center lg:sidebar-expanded:hidden xl:sidebar-expanded:block">
                            <div className="text-sm font-medium text-contrast-800 dark:text-contrast-100 truncate">Elementi aggiunti: {props.added}</div>
                        </div>
                        <div className="col-span-2 order-2 sm:order-none sm:col-span-2 text-left sm:text-center lg:sidebar-expanded:hidden xl:sidebar-expanded:block">
                            <div className="text-sm font-medium text-contrast-800 dark:text-contrast-100 truncate">Elementi aggiornati: {props.updated}</div>
                        </div>
                        <div className="col-span-1 order-1 sm:order-none sm:col-span-1 text-right sm:text-center lg:sidebar-expanded:col-span-6 xl:sidebar-expanded:col-span-4">
                            <FoBadge state={props.state ? FoState.Success : FoState.Warning} size={FoSize.Small}>
                                {props.state ? "Sincronizzazione" : "In Attesa"}
                            </FoBadge>
                        </div>
                        <div className="col-span-1 order-2 sm:order-none sm:col-span-1 text-right lg:sidebar-expanded:hidden xl:sidebar-expanded:block">
                            <FoSolidButton size={FoSize.Extrasmall} state={FoState.Danger} onClick={() => props.onClick?.()} disabled={props.state == true}>Avvia</FoSolidButton>
                        </div>
                    </div>
                </div>
            </label>
        </div>
    );
}
