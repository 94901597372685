/**
 * Umbria Mobile
 * Gestione dei dati della zona SSO Umbra
 *
 * The version of the OpenAPI document: v1.0
 * Contact: support@fovea.group
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { RequestFile } from './models';
import { DateTimeRange } from './dateTimeRange';
import { MessageType } from './messageType';

export class MessageSpecification {
    'title'?: string | null;
    'date'?: DateTimeRange;
    'type'?: MessageType;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "title",
            "baseName": "title",
            "type": "string"
        },
        {
            "name": "date",
            "baseName": "date",
            "type": "DateTimeRange"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "MessageType"
        }    ];

    static getAttributeTypeMap() {
        return MessageSpecification.attributeTypeMap;
    }
}

export namespace MessageSpecification {
}
