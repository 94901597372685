/**
 * Umbria Mobile
 * Gestione dei dati della zona SSO Umbra
 *
 * The version of the OpenAPI document: v1.0
 * Contact: support@fovea.group
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { RequestFile } from './models';
import { WarningAttach } from './warningAttach';
import { WarningDetails } from './warningDetails';
import { WarningStep } from './warningStep';

export class Warning {
    'created'?: Date;
    'updated'?: Date | null;
    'deleted'?: Date | null;
    'isDeleted'?: boolean;
    'isLock'?: boolean;
    'userId'?: string | null;
    'dataInvio'?: Date;
    'dataProtocollo'?: Date;
    'numeroProtocollo'?: string | null;
    'statoAvanzamento'?: string | null;
    'riferimentoEnte'?: string | null;
    'segnalazione'?: WarningDetails;
    'fasi'?: Array<WarningStep> | null;
    'allegati'?: Array<WarningAttach> | null;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "created",
            "baseName": "created",
            "type": "Date"
        },
        {
            "name": "updated",
            "baseName": "updated",
            "type": "Date"
        },
        {
            "name": "deleted",
            "baseName": "deleted",
            "type": "Date"
        },
        {
            "name": "isDeleted",
            "baseName": "isDeleted",
            "type": "boolean"
        },
        {
            "name": "isLock",
            "baseName": "isLock",
            "type": "boolean"
        },
        {
            "name": "userId",
            "baseName": "userId",
            "type": "string"
        },
        {
            "name": "dataInvio",
            "baseName": "data_invio",
            "type": "Date"
        },
        {
            "name": "dataProtocollo",
            "baseName": "data_protocollo",
            "type": "Date"
        },
        {
            "name": "numeroProtocollo",
            "baseName": "numero_protocollo",
            "type": "string"
        },
        {
            "name": "statoAvanzamento",
            "baseName": "stato_avanzamento",
            "type": "string"
        },
        {
            "name": "riferimentoEnte",
            "baseName": "riferimento_ente",
            "type": "string"
        },
        {
            "name": "segnalazione",
            "baseName": "segnalazione",
            "type": "WarningDetails"
        },
        {
            "name": "fasi",
            "baseName": "fasi",
            "type": "Array<WarningStep>"
        },
        {
            "name": "allegati",
            "baseName": "allegati",
            "type": "Array<WarningAttach>"
        }    ];

    static getAttributeTypeMap() {
        return Warning.attributeTypeMap;
    }
}

