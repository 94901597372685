import { FoModalService } from "../services/modal.service";
import { FoNotifyService } from "../services/notify.service";

export function FoTryCatch(options?: {
        message?: (...args: any[]) => string,
        title?: (...args: any[]) => string,
        onError?: (instance:any, ...args: any[]) => void,
        onSuccess?: (instance: any, ...args: any[]) => void,
        showMessageInModal?: boolean
    }) {
    return function (target: any, key: string, descriptor: any) {
        const originalMethod = descriptor.value;
        descriptor.value = async function (...args: any[]) {
            try {
                let result = await originalMethod.apply(this, args);
                options?.onSuccess?.(this, args);
                return result;
            } catch(e: any) {
                let title = e.traceId ?? (options && options.title ? options?.title(args) : "");
                let message = e.traceId && e.message && (e.code == null || e.code != "UNKNOWN") ? e.message : (options && options?.message ? options.message(args) : "Errore sconosciuto");
                console.error(e);
                if(options?.showMessageInModal) {
                    await FoModalService.error(message, title);
                } else {
                    FoNotifyService.error(message, title);
                }
                if(options?.onError != null)
                    options.onError(this, args);
            }
        };
        return descriptor;
    };
}