import { useState } from "react";
import { FoPositionXEnum, FoPositionYEnum } from "../enum";
import { FoAnimationProps, FoChildrenProps, FoStyleProps } from "../props";

export interface FoSlideProps extends FoChildrenProps, FoStyleProps, FoAnimationProps {
    positionX?: FoPositionXEnum,
    positionY?: FoPositionYEnum,
}

export function FoSlide(props: FoSlideProps) {
    const [show, setShow] = useState(props.show);

    if (props.show && props.show != show) { 
        setShow(!show);
        if(show) {
            props.onStart?.();
        } else {
            setTimeout(() => {
                props.onEnd?.();
            }, 500); 
        }
    }

    let animationClass = () => {
        let result = "";
        if(props.positionX == FoPositionXEnum.Right) { result += (!props.show ? "translate-x-0" : "translate-x-full"); }
        else if(props.positionX == FoPositionXEnum.Left) { result += (!props.show ? "translate-x-full " : "translate-x-0" ); }

        if(props.positionY == FoPositionYEnum.Bottom) { result += (!props.show ? "translate-y-0" : "translate-y-full"); }
        else if(props.positionY == FoPositionYEnum.Top) { result += (!props.show ? "translate-y-full" : "translate-y-0"); }
        return result;
    }

    return (
        <>
            <div className={`${props.className} transform transition ease-in-out duration-500 ${animationClass()}`}>
                {props.children}
            </div>
        </>
    );
}