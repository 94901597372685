import ReactApexChart from "react-apexcharts";
import { MeteoAggregateDto } from "../../_core/model/meteoAggregateDto";
import { FoDropDown } from "../../framework/components/inputs/dropdown";
import { FoBackdrop } from "../../framework/components/widgets/backdrop";
import { FoSpinner } from "../../framework/components/widgets/spinner";
import { FoTryCatch } from "../../framework/decorator/error.decorator";
import { FoLoading } from "../../framework/decorator/loading.decorator";
import FoPage from "../../framework/layout/page";
import { FoLoaderService } from "../../framework/services/loader.service";
import { BasePage } from "../page.base";
import Detail from "../../common/detail";
import { FoField } from "../../framework/components/inputs/field";
import { FoSolidButton } from "../../framework/components/widgets/button";
import { FoState } from "../../framework/enum";
import { StationDetails } from "../../_core/model/stationDetails";
import { FoNotifyService } from "../../framework/services/notify.service";
import { FoDatePicker } from "../../framework/components/inputs/datepicker";

//https://apexcharts.com/react-chart-demos/
export class Meteo extends BasePage<any, {
    data: MeteoAggregateDto[]
    current: MeteoAggregateDto,
    currentDetails: StationDetails
}> {
    @FoLoading("meteo")
    @FoTryCatch({ message: () => "Errore durante il recupero stazioni meteo" })
    public async componentDidMount() {
        let stazioni = await this.api.meteo_calculate();
        let details = await this.api.meteo_read_station_detail(stazioni[0].station!.id!);
        await this.setStateAsync({
            data: stazioni,
            current: stazioni[0],
            currentDetails: details
        });
    }

    private buildSource() {
        let result = this.state.data.map(i => {
            return {
                text: i.station?.name!,
                value: i,
                selected: i.station?.id == this.state.current.station?.id
            }
        });
        return result;
    }

    private async onStationChange(item: MeteoAggregateDto) {
        let details = await this.api.meteo_read_station_detail(item.station!.id!);
        await this.setStateAsync({
            current: item,
            currentDetails: details
        });
    }

    public render() {
        return <FoSpinner show={FoLoaderService.hasLoading("meteo")}>
            {FoLoaderService.hasLoading("meteo") && <FoBackdrop />}
            {this.state.current && <FoPage className="space-y-4">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h4>{this.state.current.station?.name}</h4>
                        <p className="mt-2">{this.state.current.station?.uuid}</p>
                    </div>
                    <div className="flex space-x-2">
                        {/* <FoDatePicker /> */}
                        <FoDropDown className="min-w-80" source={this.buildSource()} onItemChange={item => this.onStationChange(item.value)} />
                    </div>
                </div>
                <section className="grid md:grid-cols-2 xl:grid-cols-4 gap-6">
                    <InfoBox name="Temperatura" average={(this.state.current.last?.temperature?.toFixed(3) ?? "N.D") + "°"}
                        max={(this.state.current.last?.temperatureHigh?.toFixed(2) ?? "N.D") + "°"} min={(this.state.current.last?.temperatureLow?.toFixed(2) ?? "N.D") + "°"}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="h-6 w-6 text-white" viewBox="0 0 16 16">
                            <path d="M9.5 12.5a1.5 1.5 0 1 1-2-1.415V6.5a.5.5 0 0 1 1 0v4.585a1.5 1.5 0 0 1 1 1.415" />
                            <path d="M5.5 2.5a2.5 2.5 0 0 1 5 0v7.55a3.5 3.5 0 1 1-5 0zM8 1a1.5 1.5 0 0 0-1.5 1.5v7.987l-.167.15a2.5 2.5 0 1 0 3.333 0l-.166-.15V2.5A1.5 1.5 0 0 0 8 1" />
                        </svg>} />
                    <InfoBox name="Umidità" average={(this.state.current.last?.humidity?.toFixed(0) ?? "N.D") + "%"}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="h-6 w-6 text-white" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M7.21.8C7.69.295 8 0 8 0q.164.544.371 1.038c.812 1.946 2.073 3.35 3.197 4.6C12.878 7.096 14 8.345 14 10a6 6 0 0 1-12 0C2 6.668 5.58 2.517 7.21.8m.413 1.021A31 31 0 0 0 5.794 3.99c-.726.95-1.436 2.008-1.96 3.07C3.304 8.133 3 9.138 3 10c0 0 2.5 1.5 5 .5s5-.5 5-.5c0-1.201-.796-2.157-2.181-3.7l-.03-.032C9.75 5.11 8.5 3.72 7.623 1.82z" />
                            <path fill-rule="evenodd" d="M4.553 7.776c.82-1.641 1.717-2.753 2.093-3.13l.708.708c-.29.29-1.128 1.311-1.907 2.87z" />
                        </svg>} />
                    <InfoBox name="Vento (km/h)" average={(this.state.current.last?.windAverage?.toFixed(2) ?? "N.D")}
                        max={(this.state.current.last?.windHigh?.toFixed(2) ?? "N.D")}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="h-6 w-6 text-white" viewBox="0 0 16 16">
                            <path d="M12.5 2A2.5 2.5 0 0 0 10 4.5a.5.5 0 0 1-1 0A3.5 3.5 0 1 1 12.5 8H.5a.5.5 0 0 1 0-1h12a2.5 2.5 0 0 0 0-5m-7 1a1 1 0 0 0-1 1 .5.5 0 0 1-1 0 2 2 0 1 1 2 2h-5a.5.5 0 0 1 0-1h5a1 1 0 0 0 0-2M0 9.5A.5.5 0 0 1 .5 9h10.042a3 3 0 1 1-3 3 .5.5 0 0 1 1 0 2 2 0 1 0 2-2H.5a.5.5 0 0 1-.5-.5" />
                        </svg>} />
                    <InfoBox name="Direzione del Vento" average={(this.state.current.last?.windDirection?.toString() ?? "N.D")}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="h-6 w-6 text-white" viewBox="0 0 16 16">
                            <path d="M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016m6.5-7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0" />
                            <path d="m6.94 7.44 4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z" />
                        </svg>} />
                </section>
                <section className="grid md:grid-cols-2 xl:grid-cols-2 gap-6">
                    <div className="items-center bg-white shadow rounded-lg py-8 pr-8">
                        <h3 className="pl-8">Allarme Pioggia</h3>
                        <div className="pl-4">
                            <ReactApexChart height={400} width="550"
                                options={{
                                    chart: {
                                        type: 'bar',
                                        toolbar: {
                                            show: false
                                        }
                                    },
                                    colors: [
                                        this.state.current.hours1?.rainWarning ? '#ef4444' : '#3b82f6',
                                        this.state.current.hours3?.rainWarning ? '#ef4444' : '#3b82f6',
                                        this.state.current.hours6?.rainWarning ? '#ef4444' : '#3b82f6',
                                        this.state.current.hours12?.rainWarning ? '#ef4444' : '#3b82f6',
                                    ],
                                    plotOptions: {
                                        bar: {
                                            columnWidth: '45%',
                                            distributed: true,
                                        }
                                    },
                                    dataLabels: {
                                        enabled: false
                                    },
                                    legend: {
                                        show: false
                                    },
                                    xaxis: {
                                        categories: [
                                            '1 Ora',
                                            '3 Ore',
                                            '6 Ore',
                                            '12 Ore',
                                        ],
                                        labels: {
                                            style: {
                                                colors: [
                                                    this.state.current.hours1?.rainWarning ? '#ef4444' : '#3b82f6',
                                                    this.state.current.hours3?.rainWarning ? '#ef4444' : '#3b82f6',
                                                    this.state.current.hours6?.rainWarning ? '#ef4444' : '#3b82f6',
                                                    this.state.current.hours12?.rainWarning ? '#ef4444' : '#3b82f6',
                                                ],
                                                fontSize: '12px'
                                            }
                                        }
                                    }
                                }}
                                series={[{
                                    data: [
                                        Math.round((this.state.current.hours1?.rainMillimeters || 0) * 100) / 100,
                                        Math.round((this.state.current.hours3?.rainMillimeters || 0) * 100) / 100,
                                        Math.round((this.state.current.hours6?.rainMillimeters || 0) * 100) / 100,
                                        Math.round((this.state.current.hours12?.rainMillimeters || 0) * 100) / 100,
                                    ]
                                }]}
                                type="bar"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col bg-white shadow rounded-lg py-8 px-8">
                        <h3>Soglie per il calcolo "Allarme Pioggia"</h3>
                        <div className="space-y-8 flex-1 py-16">
                            <Detail name="Soglia 1 Ora" inline={true}>
                                <FoField className='w-full'>
                                    <input type="text" className={`fo-input-no-default`}
                                        onChange={(evt: any) => { this.state.currentDetails!.hours1! = evt.target.value; this.setState({}) }}
                                        value={this.state.currentDetails?.hours1 ?? ""} />
                                </FoField>
                            </Detail>
                            <Detail name="Soglia 3 Ora" inline={true}>
                                <FoField className='w-full'>
                                    <input type="text" className={`fo-input-no-default`}
                                        onChange={(evt: any) => { this.state.currentDetails!.hours3! = evt.target.value; this.setState({}) }}
                                        value={this.state.currentDetails?.hours3 ?? ""} />
                                </FoField>
                            </Detail>
                            <Detail name="Soglia 6 Ora" inline={true}>
                                <FoField className='w-full'>
                                    <input type="text" className={`fo-input-no-default`}
                                        onChange={(evt: any) => { this.state.currentDetails!.hours6! = evt.target.value; this.setState({}) }}
                                        value={this.state.currentDetails?.hours6 ?? ""} />
                                </FoField>
                            </Detail>
                            <Detail name="Soglia 12 Ore" inline={true}>
                                <FoField className='w-full'>
                                    <input type="text" className={`fo-input-no-default`}
                                        onChange={(evt: any) => { this.state.currentDetails!.hours12! = evt.target.value; this.setState({}) }}
                                        value={this.state.currentDetails?.hours12 ?? ""} />
                                </FoField>
                            </Detail>
                        </div>
                        <FoSolidButton state={FoState.Primary} onClick={() => this.update()}>Salva</FoSolidButton>
                    </div>
                </section>
            </FoPage>}
        </FoSpinner>;
    }

    @FoTryCatch({ message: () => "Errore durante il salvataggio delle soglie", onSuccess: () => FoNotifyService.success("Soglie aggiornate con successo") })
    @FoLoading("meteo")
    public async update() {
        let id = this.state.current.station!.id!;
        await this.api.meteo_write_station_detail(id, this.state.currentDetails);
        let stazioni = await this.api.meteo_calculate();
        await this.setStateAsync({
            data: stazioni,
            current: stazioni.find(s => s.station!.id == id),
        });
    }
}

interface InfoBoxProps {
    name: string;
    average: string;
    max?: string;
    min?: string;
    icon: any;
}

function InfoBox(props: InfoBoxProps) {
    return (
        <div className="relative overflow-hidden rounded-lg bg-white px-4 shadow sm:px-4 sm:py-8">
            <dt>
                <div className="absolute rounded-md bg-primary-500 p-3">
                    {props.icon}
                </div>
                <p className="ml-16 truncate text-sm font-medium text-contrast-500">{props.name}</p>
            </dt>
            <dd className="ml-16 flex items-baseline">
                <p className="text-xl font-semibold text-contrast-900">{props.average}</p>
                {props.max && <p className="ml-1 flex items-baseline text-sm font-semibold text-green-600">
                    <svg className="h-5 w-5 flex-shrink-0 self-center text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                        <path fill-rule="evenodd" d="M10 17a.75.75 0 0 1-.75-.75V5.612L5.29 9.77a.75.75 0 0 1-1.08-1.04l5.25-5.5a.75.75 0 0 1 1.08 0l5.25 5.5a.75.75 0 1 1-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0 1 10 17Z" clip-rule="evenodd" />
                    </svg>
                    {props.max}
                </p>}
                {props.min && <p className="ml-1 flex items-baseline text-sm font-semibold text-red-600">
                    <svg className="h-5 w-5 flex-shrink-0 self-center text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                        <path fill-rule="evenodd" d="M10 3a.75.75 0 0 1 .75.75v10.638l3.96-4.158a.75.75 0 1 1 1.08 1.04l-5.25 5.5a.75.75 0 0 1-1.08 0l-5.25-5.5a.75.75 0 1 1 1.08-1.04l3.96 4.158V3.75A.75.75 0 0 1 10 3Z" clip-rule="evenodd" />
                    </svg>
                    {props.min}
                </p>}
            </dd>
        </div>
    );
}
