/**
 * Umbria Mobile
 * Gestione dei dati della zona SSO Umbra
 *
 * The version of the OpenAPI document: v1.0
 * Contact: support@fovea.group
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { RequestFile } from './models';
import { DoubleRange } from './doubleRange';

export class AttractionSpecification {
    'ids'?: Array<number> | null;
    'name'?: string | null;
    'municipality'?: string | null;
    'latitude'?: DoubleRange;
    'longitude'?: DoubleRange;
    'visible'?: boolean | null;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "ids",
            "baseName": "ids",
            "type": "Array<number>"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "municipality",
            "baseName": "municipality",
            "type": "string"
        },
        {
            "name": "latitude",
            "baseName": "latitude",
            "type": "DoubleRange"
        },
        {
            "name": "longitude",
            "baseName": "longitude",
            "type": "DoubleRange"
        },
        {
            "name": "visible",
            "baseName": "visible",
            "type": "boolean"
        }    ];

    static getAttributeTypeMap() {
        return AttractionSpecification.attributeTypeMap;
    }
}

