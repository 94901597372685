/**
 * Umbria Mobile
 * Gestione dei dati della zona SSO Umbra
 *
 * The version of the OpenAPI document: v1.0
 * Contact: support@fovea.group
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { RequestFile } from './models';
import { GlobalizationText } from './globalizationText';

export class Event {
    'created'?: Date;
    'updated'?: Date | null;
    'deleted'?: Date | null;
    'isDeleted'?: boolean;
    'isLock'?: boolean;
    'userId'?: string | null;
    'id'?: number;
    'sourceId'?: number | null;
    'url'?: string | null;
    'mainImage'?: string | null;
    'images'?: Array<string> | null;
    'municipality'?: string | null;
    'latitude'?: number | null;
    'longitude'?: number | null;
    'start'?: Date | null;
    'end'?: Date | null;
    'name'?: Array<GlobalizationText> | null;
    'description'?: Array<GlobalizationText> | null;
    '_abstract'?: Array<GlobalizationText> | null;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "created",
            "baseName": "created",
            "type": "Date"
        },
        {
            "name": "updated",
            "baseName": "updated",
            "type": "Date"
        },
        {
            "name": "deleted",
            "baseName": "deleted",
            "type": "Date"
        },
        {
            "name": "isDeleted",
            "baseName": "isDeleted",
            "type": "boolean"
        },
        {
            "name": "isLock",
            "baseName": "isLock",
            "type": "boolean"
        },
        {
            "name": "userId",
            "baseName": "userId",
            "type": "string"
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "sourceId",
            "baseName": "sourceId",
            "type": "number"
        },
        {
            "name": "url",
            "baseName": "url",
            "type": "string"
        },
        {
            "name": "mainImage",
            "baseName": "mainImage",
            "type": "string"
        },
        {
            "name": "images",
            "baseName": "images",
            "type": "Array<string>"
        },
        {
            "name": "municipality",
            "baseName": "municipality",
            "type": "string"
        },
        {
            "name": "latitude",
            "baseName": "latitude",
            "type": "number"
        },
        {
            "name": "longitude",
            "baseName": "longitude",
            "type": "number"
        },
        {
            "name": "start",
            "baseName": "start",
            "type": "Date"
        },
        {
            "name": "end",
            "baseName": "end",
            "type": "Date"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "Array<GlobalizationText>"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "Array<GlobalizationText>"
        },
        {
            "name": "_abstract",
            "baseName": "abstract",
            "type": "Array<GlobalizationText>"
        }    ];

    static getAttributeTypeMap() {
        return Event.attributeTypeMap;
    }
}

