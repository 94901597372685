
export class FoveaHttpRequest {
    public async get<T>(url: string): Promise<T | undefined | null> {
        var options = {
            method: 'GET',
            credentials: sessionStorage.getItem("SOOUser") ? "include" : undefined,
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                Authorization: `Basic ${btoa(`${sessionStorage.getItem("SOOUser")}:${sessionStorage.getItem("SOOPassword")}`)}`
            },
        } as any;
        let response: Response = await fetch(url, options);
        return await this.parseResult<T>(response);
    }
    public async post<T>(url: string, item: any | FormData): Promise<T | undefined | null> {
        let response: Response = await fetch(url, {
            method: 'POST',
            credentials: sessionStorage.getItem("SOOUser") ? "include" : undefined,
            body: item instanceof FormData ? item : JSON.stringify(item),
            headers: item instanceof FormData ? { Authorization: `Basic ${btoa(`${sessionStorage.getItem("SOOUser")}:${sessionStorage.getItem("SOOPassword")}`)}` } : {
                'Content-type': 'application/json; charset=UTF-8',
                Authorization: `Basic ${btoa(`${sessionStorage.getItem("SOOUser")}:${sessionStorage.getItem("SOOPassword")}`)}`
            },
        });
        return await this.parseResult<T>(response);
    }
    public async put<T>(url: string, item: any | FormData): Promise<T | undefined | null> {
        let response: Response = await fetch(url, {
            method: 'PUT',
            credentials: sessionStorage.getItem("SOOUser") ? "include" : undefined,
            body: item instanceof FormData ? item : JSON.stringify(item),
            headers: item instanceof FormData ? { Authorization: `Basic ${btoa(`${sessionStorage.getItem("SOOUser")}:${sessionStorage.getItem("SOOPassword")}`)}` } : {
                'Content-type': 'application/json; charset=UTF-8',
                Authorization: `Basic ${btoa(`${sessionStorage.getItem("SOOUser")}:${sessionStorage.getItem("SOOPassword")}`)}`
            },
        });
        return await this.parseResult<T>(response);
    }
    public async patch<T>(url: string, item: any | FormData): Promise<T | undefined | null> {
        let response: Response = await fetch(url, {
            method: 'PATCH',
            credentials: sessionStorage.getItem("SOOUser") ? "include" : undefined,
            body: item instanceof FormData ? item : JSON.stringify(item),
            headers: item instanceof FormData ? { Authorization: `Basic ${btoa(`${sessionStorage.getItem("SOOUser")}:${sessionStorage.getItem("SOOPassword")}`)}` } : {
                'Content-type': 'application/json; charset=UTF-8',
                Authorization: `Basic ${btoa(`${sessionStorage.getItem("SOOUser")}:${sessionStorage.getItem("SOOPassword")}`)}`
            },
        });
        return await this.parseResult<T>(response);
    }
    public async delete<T>(url: string): Promise<T | undefined | null> {
        let response: Response = await fetch(url, {
            method: 'DELETE',
            credentials: sessionStorage.getItem("SOOUser") ? "include" : undefined,
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                Authorization: `Basic ${btoa(`${sessionStorage.getItem("SOOUser")}:${sessionStorage.getItem("SOOPassword")}`)}`
            },
        });
        return await this.parseResult<T>(response);
    }

    private async parseResult<T>(response: Response): Promise<T> {
        if(response.ok) {
            let body = await response.text();
            if(response.status == 204 || body == "")
                return null as T;
            else if(response.status >= 500)
                throw JSON.parse(body);
            else
                return JSON.parse(body);
        } else {
            throw JSON.parse(await response.text());
        }
    }
}