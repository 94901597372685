import { Taxi } from '../../../_core/model/taxi';
import EmptyElements from '../../../common/empty-elements';
import { FoField } from '../../../framework/components/inputs/field';
import FoTable, { FoTableColumn } from '../../../framework/components/table';
import { FoBackdrop } from '../../../framework/components/widgets/backdrop';
import { FoSpinner } from '../../../framework/components/widgets/spinner';
import { FoTryCatch } from '../../../framework/decorator/error.decorator';
import { FoLoading } from '../../../framework/decorator/loading.decorator';
import { FoConfirm } from '../../../framework/decorator/modal.decorator';
import { FoPositionXEnum } from '../../../framework/enum';
import FoPage from '../../../framework/layout/page';
import { FoLoaderService } from '../../../framework/services/loader.service';
import { BasePage } from '../../page.base';

export class Taxis extends BasePage<any, {
    data: Taxi[]
}> {
    @FoLoading("taxis")
    @FoTryCatch({ message: () => "Errore durante il recupero dei taxi" })
    public async componentDidMount() {
        let data = await this.api.taxi_read();
        data.forEach(t => (t as any).edit = false);
        this.setState({ data: data })
    }

    private isEditable(entity: Taxi): boolean {
        return (entity as any).edit || entity.id == null;
    }

    public render() {
        return <>
            <FoSpinner show={FoLoaderService.hasLoading("taxis")}>
                {FoLoaderService.hasLoading("taxis") && <FoBackdrop />}
                <FoPage>
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h4>Servizio Taxi</h4>
                            <p className="mt-2">Lista di tutti i servizi taxi</p>
                        </div>
                        <div className="flex space-x-2">
                            <button onClick={() => this.setState({ data: [new Taxi(), ...this.state.data] })} className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none fo-button fo-button-fill fo-primary">Aggiungi Servizio Taxi</button>
                        </div>
                    </div>
                    <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <FoTable source={this.state.data ?? []} onChildrenChange={() => this.setState({})}>
                                    <FoTableColumn text='Nome' field='name' className="p-2" xPosition={FoPositionXEnum.Left} width={25} template={(item: Taxi, index: number, onChange?: () => void) => {
                                        return <>{!this.isEditable(item) ? <div className="text-wrap pr-4 font-medium text-contrast-900">{item.name}</div> :
                                            <FoField className='w-full'>
                                                <input type="text" className={`fo-input-no-default`}
                                                    onChange={(evt: any) => { item.name = evt.target.value; onChange?.(); }}
                                                    value={item.name ?? ""} />
                                            </FoField>}</>
                                    }} />
                                    <FoTableColumn text="Via" field="address" className="p-2" width={25} template={(item: Taxi, index: number, onChange?: () => void) => {
                                        return <>{!this.isEditable(item) ? <span className="text-wrap">{item.address}</span> :
                                        <FoField className='w-full'>
                                            <input type="text" className={`fo-input-no-default`}
                                                onChange={(evt: any) => { item.address = evt.target.value; onChange?.(); }}
                                                value={item.address ?? ""} />
                                        </FoField>}</>
                                    }} />
                                    <FoTableColumn text="Telefono" field="phone" className="p-2" width={20} template={(item: Taxi, index: number, onChange?: () => void) => {
                                        return <>{!this.isEditable(item) ? <span className="pl-4 text-wrap">{item.phone}</span> :
                                        <FoField className='w-full'>
                                            <input type="text" className={`fo-input-no-default`}
                                                onChange={(evt: any) => { item.phone = evt.target.value; onChange?.(); }}
                                                value={item.phone ?? ""} />
                                        </FoField>}</>
                                    }} />
                                    <FoTableColumn text="Email" field="Email" className="p-2" width={20} template={(item: Taxi, index: number, onChange?: () => void) => {
                                        return <>{!this.isEditable(item) ? <span className="pl-4 text-wrap">{item.email ?? 'N.D.'}</span> :
                                        <FoField className='w-full'>
                                            <input type="text" className={`fo-input-no-default`}
                                                onChange={(evt: any) => { item.email = evt.target.value; onChange?.(); }}
                                                value={item.email ?? ""} />
                                        </FoField>}</>
                                    }} />
                                    <FoTableColumn width={10} xPosition={FoPositionXEnum.Center} template={(item: Taxi, index: number, onChange?: (item: any) => void) => {
                                        return <span className="space-x-1">
                                            {!this.isEditable(item) && <button onClick={() => { (item as any).edit = true; onChange?.(null); }} className='hover:text-primary-700'>
                                                <svg className='h-5 w-5' fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                                    <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
                                                    <path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
                                                </svg>
                                            </button>}
                                            {this.isEditable(item) && <button onClick={() => { this.createOrUpdate(item); }} className='hover:text-success-700'>
                                                <svg className='h-5 w-5' fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                                    <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                                                </svg>
                                            </button>}
                                            <button onClick={() => { this.remove(item) }} className='hover:text-danger-700'>
                                                <svg className='h-5 w-5' fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                                    <path fill-rule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clip-rule="evenodd" />
                                                </svg>
                                            </button>
                                        </span>
                                    }} />
                                </FoTable>
                                {!(this.state.data?.length > 0) && <EmptyElements description='Nessun Servizio di Taxi trovato' />}
                            </div>
                        </div>
                    </div>
                </FoPage>
            </FoSpinner>
        </>;
    }

    @FoLoading("taxis")
    @FoConfirm({ message: () => "Sicuro di voler cancellare il servizio Taxi?" })
    @FoTryCatch({ message: () => "Errore durante la cancellazione del servizio" })
    private async remove(taxi: Taxi): Promise<void> {
        await this.api.taxi_delete(taxi);
        this.setState({ data: this.state.data.filter(t => t.id != taxi.id) });
    }

    @FoLoading("taxis")
    @FoConfirm({ message: (args: any[]) => { return "Sicuro di voler " + (args[0].id != null ? "modificare" : "aggiungere") + " il servizio Taxi?" } })
    @FoTryCatch({ message: (args: any[]) => "Errore durante " + (args[0].id != null ? "la modifica" : "l'aggiunta") + " del servizio Taxi" })
    private async createOrUpdate(taxi: Taxi): Promise<void> {
        if (taxi.id != null)
            await this.api.taxi_update(taxi);
        else {
            let result = await this.api.taxi_create(taxi);
            taxi.id = result.id;
        }
        (taxi as any).edit = false;
        this.setState({ data: this.state.data });
    }
}
