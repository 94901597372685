import React from "react";
import { UmbriaService } from "../_core/api.service";
import { useParams } from 'react-router-dom';

export function withParams(Component: any) {
    return (props: any) => <Component {...props} params={useParams()} />;
}

export class BasePage<TProps, TState> extends React.Component<TProps, TState> {
    constructor(props: any) {
        super(props);
        this.state = {} as TState;
    }
    protected api: UmbriaService = new UmbriaService();
    protected setStateAsync(item: any): Promise<any> {
        return new Promise(resolve => {
            this.setState(item, () => resolve(null));
        });
    }
    protected navigate(url: string) {
        window.history.pushState({}, '', url);
        window.dispatchEvent(new PopStateEvent('popstate')); // Necessario per far reagire React Router al cambiamento dell'URL
    };
    protected refresh(callback?: () => void) {
        this.setState({}, callback);
    }
}