import { useEffect, useState } from "react";
import { FoStyleProps } from "../../props";

export interface FoFile extends File {
    size: number;
    type: string;
    content: File
}

export interface FoInputFileProps extends FoStyleProps {
    multiple?: boolean;
    accept?: string;
    text?: string;
    onUpload?: (files: FoFile[]) => void;
}

export function FoInputFile(props: FoInputFileProps) {
    const [files, setFiles] = useState([] as any);
    const handleFileChange = (event: any) => {
        if(props.multiple)
            setFiles([...files, ...event.target.files]);
        else
            setFiles([...event.target.files]);
    };
    useEffect(() => {
        if (props.onUpload != null && files.length > 0) {
            const result = [] as FoFile[];
            files.forEach((file: any) => {
                result.push({
                    ...file as FoFile,
                    content: file
                });
            });
            props.onUpload(result);
        }
    }, [files]);
    return (
        <label className="shadow-blue-100 mt-2 block rounded-full border bg-white px-4 py-0.5 font-normal text-blue-500 shadow hover:bg-blue-50">
            <input className="hidden" type="file" multiple={props.multiple ?? false} accept={props.accept} onChange={handleFileChange} />
            {props.text ?? "Sfoglia"}
        </label>
    );
}
