import { useState } from "react";
import { FoChildrenProps, FoStyleProps } from "../props";

export interface FoPageProps extends FoChildrenProps, FoStyleProps {
}

export default function FoPage(props: FoPageProps) {
    return (
        <div className={`${props.className} mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8`}>
            {props.children}
        </div>
    );
}
