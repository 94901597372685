export enum FoTypeEnum {
    String = "string",
    Number = "number",
    Boolean = "boolean",
    Date = "date"
}

export enum FoPositionXEnum {
    Left,
    Right,
    Center
}

export enum FoPositionYEnum {
    Top,
    Bottom,
    Middle
}

export enum FoState {
    Primary = "primary",
    Contrast = "contrast",
    Success = "success",
    Warning = "warning",
    Danger = "danger",
    Info = "info"
}

export enum FoSize {
    Medium = "md",
    Extrasmall = "xs",
    Small = "sm",
    Large = "lg",
    Extralarge = "xl"
}