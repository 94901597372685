import React, { } from "react";
import { FoChildrenProps } from "../props";
import { FoAction, FoUtility } from "../core";
import { FoSize } from "../enum";
import { FoBackdrop } from "../components/widgets/backdrop";

export class FoModalService extends React.Component<FoChildrenProps, { 
        show: boolean, 
        modals: { 
            id: number, 
            show: boolean, 
            instance: React.ReactNode | any,
            opt?: {
                size?: FoSize
            }
        }[] }> {
    public async componentDidMount() {
        this.setState({ show: false, modals: [] });
        FoModalService.service = this;
    }

    public render() {
        return <>
            <div className={`relative z-30 ${this.state?.modals?.length > 0 ? '' : 'hidden'}`}>
                <FoBackdrop full={true} />
                {this.state?.modals?.map((modal: { id: number, show: boolean, instance: React.ReactNode | any, opt?: {
                size?: FoSize
                }}) => (
                    <div key={FoUtility.hash(modal)} className={`fixed inset-0 z-10 w-screen overflow-y-auto
                            ${modal.show ? "ease-out duration-200 opacity-100 translate-y-0 sm:scale-100" : "ease-in duration-200 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"}`}>
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <div className={`fo-box w-full ${modal.opt?.size ? "fo-" + modal.opt.size.toString() : ""} rounded-lg`}>
                                {modal.instance}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {this.props.children}
        </>;
    }

    private static service: FoModalService;
    public static show(modal: (close: (value: any) => void) => React.ReactNode | any, opt?: { size?: FoSize }): Promise<any> {
        let id = FoUtility.hash(new Date());
        return new Promise<any>((res) => {
            let instance = modal(res);
            FoModalService.service.state.modals.push({ id: id, show: false, instance: instance, opt: opt });
            FoModalService.service.setState({ modals: FoModalService.service.state.modals }, () => {
                let modal = FoModalService.service.state.modals.find(m => m.id == id);
                if(modal) { modal.show = true; }

                setTimeout(() => {
                    FoModalService.service.setState({ modals: FoModalService.service.state.modals });
                    setTimeout(() => FoModalService.service.setState({ show: true }), 1);
                }, 1);
            });
        }).finally(() => {
            let modal = FoModalService.service.state.modals.find(m => m.id == id);
            if(modal) { modal.show = false; }

            FoModalService.service.setState({ modals: FoModalService.service.state.modals }, () => {
                let modals = FoModalService.service.state.modals.filter(m => m.id != id);
                setTimeout(() => FoModalService.service.setState({ show: modals.length > 0 }, () => {
                    setTimeout(() => FoModalService.service.setState({ modals: modals }), 100);
                }), 100);
            });
        });
    }

    public static async form<T>(
        body: (close: (value: any) => void) => React.ReactNode | any,
        opts?: {
            closable?: boolean,
            closableValue?: any,
            size?: FoSize
        },): Promise<T> {
        return await FoModalService.show((close: any) => {
            return <>
                {(opts?.closable == undefined || opts.closable) && <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button className="fo-button fo-button-sm fo-paragraph" onClick={() => close(opts?.closableValue)}>
                        <span className="sr-only">Close</span>
                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>}
                {body(close)}
            </>
        }, { size: opts?.size})
    }

    public static async message<T>(
        body: string | React.ReactNode | any = "",
        title: string = "Attenzione",
        opts?: {
            icon?: React.ReactNode | any,
            actions?: FoAction[],
            closable?: boolean,
            size?: FoSize
        },): Promise<T> {
        return await FoModalService.form((close) => {
            return <>
                <div className="sm:flex sm:items-start">
                    {opts?.icon}
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <h4>{title}</h4>
                        <div className={`fo-paragraph`}>
                            {body}
                        </div>
                    </div>
                </div>
                {opts?.actions && <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    {opts?.actions.map((a) => <button key={FoUtility.hash(a)} type="button" className={`mr-3 first:mr-0 inline-flex" } ${a.class}`} onClick={() => a.action(close)}>{a.text}</button>)}
                </div>}
            </>
        }, { closable: opts?.closable, closableValue: false, size: opts?.size})
    }

    public static async confirm(message?: string, title?: string): Promise<boolean> {
        message = message ?? "Sei sicuro di voler procedere con l'operazione scelta?";
        title = title ?? "Conferma";
        return await FoModalService.message<boolean>(message, title, {
            icon: <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-info-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg className="h-6 w-6 text-info-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
            </svg></div>,
            actions: [{ text: "Conferma", class: "fo-button fo-button-fill fo-info fo-sm", action: (close) => close(true) }, { text: "Annulla", class: "fo-button fo-button-border fo-button-semifill fo-sm", action: (close) => close(false) }],
            closable: true,
            size: FoSize.Small
        });
    }

    public static async error(message?: string, title?: string, actions?: FoAction[], closable?: boolean): Promise<boolean> {
        message = message ?? "Qualcosa è andato storto durante l'operazione";
        title = title ?? "Attenzione";
        return await FoModalService.message<boolean>(message, title, {
            icon: <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-danger-100 sm:mx-0 sm:h-10 sm:w-10"><svg className="h-6 w-6 text-danger-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
            </svg></div>,
            actions: actions,
            closable: closable,
            size: FoSize.Small
        });
    }
}