import { User } from "../../_core/model/user";
import { FoField } from "../../framework/components/inputs/field";
import { FoTryCatch } from "../../framework/decorator/error.decorator";
import { FoLoading } from "../../framework/decorator/loading.decorator";
import { FoNotifyService } from "../../framework/services/notify.service";
import { BasePage } from "../page.base";

export class Login extends BasePage<any, { user: User }> {

    public async componentDidMount() {
        this.setState({ user: new User() });
        document.addEventListener("keydown", (event) => {
            if (event.key === "Enter" && this.state.user.username && this.state.user.password) {
                this.login();
            }
        });
    }

    public render() {
        return <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img className="mx-auto h-16 w-auto" src={require('../../logo.png')} alt="Sud-Ovest Orvietano" />
                <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-contrast-900">SOOrvietano</h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                    <FoField label="Username">
                        <input type="text" className={`fo-input-no-default`}
                            onChange={(evt: any) => { this.setState({ user: { username: evt.target.value, password: this.state.user.password } }) }}
                            value={this.state.user?.username ?? ""} />
                    </FoField>
                    <FoField label="Password" className="mt-5">
                        <input type="password" className={`fo-input-no-default`}
                            onChange={(evt: any) => { this.setState({ user: { username: this.state.user.username, password: evt.target.value } }) }}
                            value={this.state.user?.password ?? ""} />
                    </FoField>
                    <button className="mt-10 w-full fo-button fo-button-fill fo-primary"
                        onClick={() => this.login()} disabled={this.state.user?.username == null || this.state.user?.password == null}>Accedi</button>
                </div>
            </div>
        </div>;
    }

    @FoTryCatch({ message: () => "Errore durante la login" })
    @FoLoading("login")
    public async login() {
        if (await this.api.admin_login(this.state.user)) {
            sessionStorage.setItem("SOOUser", this.state.user.username!);
            sessionStorage.setItem("SOOPassword", this.state.user.password!);
            this.navigate("/");
        } else {
            FoNotifyService.error("Credenziali errate");
        }
    }
}
