/**
 * Umbria Mobile
 * Gestione dei dati della zona SSO Umbra
 *
 * The version of the OpenAPI document: v1.0
 * Contact: support@fovea.group
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { RequestFile } from './models';
import { Message } from './message';

export class MessagePaginateResult {
    'total'?: number;
    'size'?: number;
    'page'?: number;
    'items'?: Array<Message> | null;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "total",
            "baseName": "total",
            "type": "number"
        },
        {
            "name": "size",
            "baseName": "size",
            "type": "number"
        },
        {
            "name": "page",
            "baseName": "page",
            "type": "number"
        },
        {
            "name": "items",
            "baseName": "items",
            "type": "Array<Message>"
        }    ];

    static getAttributeTypeMap() {
        return MessagePaginateResult.attributeTypeMap;
    }
}

