import { Routes, Route, NavLink, useLocation } from 'react-router-dom';
import { Dashboard } from './pages/dashboard/dashboard';
import { Events } from './pages/data/events/event';
import { Attractions } from './pages/data/attraction/attraction';
import AttractionDetail from './pages/data/attraction/attraction-detail';
import { useEffect, useReducer, useState } from 'react';
import { FoOpacity } from './framework/animations/opacity';
import EventDetail from './pages/data/events/event-detail';
import { Warnings } from './pages/warning/warning';
import WarningDetail from './pages/warning/warning-detail';
import { Login } from './pages/login/login';
import { Meteo } from './pages/meteo/meteo';
import { Settings } from './pages/configuration/setting';
import { Taxis } from './pages/mobilita/taxi/taxi';
import { Products } from './pages/data/products/product';
import ProductDetail from './pages/data/products/product-detail';
import MessageDetail from './pages/configuration/message/message-detail';
import { Messages } from './pages/configuration/message/message';
import RouteDetail from './pages/data/routes/route-detail';
import { RoutesPage } from './pages/data/routes/route';

export default function App() {
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const location = useLocation();

  useEffect(() => {
    forceUpdate();
  }, [location]);

  useEffect(() => {
    if(sessionStorage.getItem("SOOUser") == null || sessionStorage.getItem("SOOPassword") == null) {
      window.history.pushState({}, '', "/login");
      window.dispatchEvent(new PopStateEvent('popstate'));
    }
  }, [])
  
  return (<>
    <div className="w-full text-contrast-900">
      {sessionStorage.getItem("SOOUser") != null && sessionStorage.getItem("SOOPassword") != null && <nav className="border-b border-contrast-200 bg-white">
        <div className="w-full px-4 sm:px-6 lg:px-8">
          <div className="flex  h-16 justify-between">
            <div className="relative flex flex-shrink-0 items-center">
              <img className="block h-8 w-auto lg:hidden" src={require('./logo-transparent.png')} alt="Sud-Ovest Orvietano" />
              <img className="hidden h-8 w-auto lg:block" src={require('./logo-transparent.png')} alt="Sud-Ovest Orvietano" />
              <h5 className='ml-3'>Sud-Ovest Orvietano</h5>
            </div>
            <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8 pt-3">
              <UmbriaJob name='Dashboard' url='/' onChange={() => forceUpdate()} />
              <UmbriaJob name='Dati' items={[
                {
                  name: 'Attrazioni',
                  description: 'Gestisci le attrazioni turistiche del Sud Ovest Orvietano',
                  icon: <svg className="h-6 w-6 text-contrast-600 group-hover:text-primary-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path d="M11.584 2.376a.75.75 0 0 1 .832 0l9 6a.75.75 0 1 1-.832 1.248L12 3.901 3.416 9.624a.75.75 0 0 1-.832-1.248l9-6Z" />
                    <path fill-rule="evenodd" d="M20.25 10.332v9.918H21a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1 0-1.5h.75v-9.918a.75.75 0 0 1 .634-.74A49.109 49.109 0 0 1 12 9c2.59 0 5.134.202 7.616.592a.75.75 0 0 1 .634.74Zm-7.5 2.418a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Zm3-.75a.75.75 0 0 1 .75.75v6.75a.75.75 0 0 1-1.5 0v-6.75a.75.75 0 0 1 .75-.75ZM9 12.75a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Z" clip-rule="evenodd" />
                    <path d="M12 7.875a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z" />
                  </svg>,
                  url: '/attractions'
                },
                {
                  name: 'Eventi',
                  description: 'Gestisci gli eventi del Sud Ovest Orvietano',
                  icon: <svg className="h-6 w-6 text-contrast-600 group-hover:text-primary-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
                    <path fill-rule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clip-rule="evenodd" />
                  </svg>,
                  url: '/events'
                },
                {
                  name: 'Prodotti',
                  description: 'Gestisci i prodotti tipici del Sud Ovest Orvietano',
                  icon: <svg className="h-6 w-6 text-contrast-600 group-hover:text-primary-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path d="M5.625 3.75a2.625 2.625 0 1 0 0 5.25h12.75a2.625 2.625 0 0 0 0-5.25H5.625ZM3.75 11.25a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5H3.75ZM3 15.75a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75ZM3.75 18.75a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5H3.75Z" />
                  </svg>,
                  url: '/products'
                },
                {
                  name: 'Sentieri',
                  description: 'Visualizza i sentieri del Sud Ovest Orvietano',
                  icon: <svg className="h-6 w-6 text-contrast-600 group-hover:text-primary-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M15.75 4.5a3 3 0 1 1 .825 2.066l-8.421 4.679a3.002 3.002 0 0 1 0 1.51l8.421 4.679a3 3 0 1 1-.729 1.31l-8.421-4.678a3 3 0 1 1 0-4.132l8.421-4.679a3 3 0 0 1-.096-.755Z" clip-rule="evenodd" />
                  </svg>,
                  url: '/routes'
                },
              ]} onChange={() => forceUpdate()} />
              <UmbriaJob name='Mobilita' items={[
                {
                  name: 'Taxi',
                  description: 'Gestisci i servizi taxi del Sud Ovest Orvietano',
                  icon: <svg className="h-6 w-6 text-contrast-600 group-hover:text-primary-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M15 3.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0V5.56l-4.72 4.72a.75.75 0 1 1-1.06-1.06l4.72-4.72h-2.69a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
                    <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clip-rule="evenodd" />
                  </svg>,
                  url: '/taxi'
                }
              ]} onChange={() => forceUpdate()} />
              {/* <UmbriaJob name='Warnings' url='/warnings' /> */}
              <UmbriaJob name='Meteo' url='/meteo' onChange={() => forceUpdate()} />
              <UmbriaJob name='Configurazioni' items={[
                {
                  name: 'Messaggi',
                  description: 'Gestisci i servizi taxi del Sud Ovest Orvietano',
                  icon: <svg className="h-6 w-6 text-contrast-600 group-hover:text-primary-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 0 1-3.476.383.39.39 0 0 0-.297.17l-2.755 4.133a.75.75 0 0 1-1.248 0l-2.755-4.133a.39.39 0 0 0-.297-.17 48.9 48.9 0 0 1-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97ZM6.75 8.25a.75.75 0 0 1 .75-.75h9a.75.75 0 0 1 0 1.5h-9a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5H12a.75.75 0 0 0 0-1.5H7.5Z" clip-rule="evenodd" />
                  </svg>,
                  url: '/messages'
                }
              ]} onChange={() => forceUpdate()} />
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:items-center space-x-6">
              <button type="button" className="relative rounded-full bg-white p-1 text-contrast-400 hover:text-contrast-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                <span className="absolute -inset-1.5"></span>
                <span className="sr-only">View notifications</span>
              </button>
              <div className="relative">
                <div>
                  <div className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                    <span className="absolute -inset-1.5"></span>
                    <span className="sr-only">Open user menu</span>
                    <span className="inline-block size-8 overflow-hidden rounded-full bg-gray-100">
                      <svg className="size-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>}

      <main>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/attractions" element={<Attractions />} />
          <Route path="/attractions/:id" element={<AttractionDetail />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:id" element={<EventDetail />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:id" element={<ProductDetail />} />
          <Route path="/routes" element={<RoutesPage />} />
          <Route path="/routes/:id" element={<RouteDetail />} />
          <Route path="/warnings" element={<Warnings />} />
          <Route path="/warnings/:id" element={<WarningDetail />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/messages/:id" element={<MessageDetail />} />
          <Route path="/meteo" element={<Meteo />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/taxi" element={<Taxis />} />
          {/* <Route path="/policy/:id" element={<PolicyDetail />} /> */}
        </Routes>
      </main>
    </div>
  </>
  )
}

interface UmbriaMenu {
  name: string,
  url?: string,
  onChange: () => void,
  items?: { icon: any, name: string, description: string, url: string }[]
}

function UmbriaJob(props: UmbriaMenu) {
  const [showMenu, setShowMenu] = useState(false);
  let enable = () => {
    if (props.url == "/")
      return window.location.pathname == props.url;

    let urls = [props.url];
    props.items?.forEach(i => urls.push(i.url));
    urls = urls.filter(u => u != null && u != undefined);
    return urls.filter(url => window.location.pathname.startsWith(url as string)).length > 0;
  }
  return (
    <>

      {(props.items == null || props.items.length == 0) && <NavLink to={props.url || '/'} onClick={() => props.onChange()}
        className={`pb-3 inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium 
                ${enable() ? 'border-primary-500' : 'border-transparent text-contrast-500 hover:border-contrast-300 hover:text-contrast-700'}`}>{props.name}</NavLink>}

      {props.items != null && props.items?.length > 0 && <div onMouseEnter={() => setShowMenu(true)} onMouseLeave={() => setShowMenu(false)}
        className={`relative pb-3 inline-flex items-center border-b-2 
      ${enable() ? 'border-primary-500 text-black' : 'border-transparent text-contrast-500 hover:border-contrast-300 hover:text-contrast-700'}`}>
        <span className={`px-1 pt-1 text-sm cursor-pointer font-medium text-inherit`}>{props.name}</span>
        <FoOpacity show={showMenu} className='absolute z-20 top-12 left-1/2 -translate-x-1/2'>
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-contrast-900/5">
            <div className="p-4">
              {props.items?.map(i => {
                return <>
                  <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-contrast-50">
                    <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-contrast-50 group-hover:bg-white">
                      {i.icon}
                    </div>
                    <div>
                      <NavLink to={i.url} onClick={() => { props.onChange(); setShowMenu(false); }} className="font-semibold text-contrast-900">
                        {i.name}
                        <span className="absolute inset-0"></span>
                      </NavLink>
                      <p className="mt-1 text-contrast-600">{i.description}</p>
                    </div>
                  </div>
                </>;
              })}
            </div>
          </div>
        </FoOpacity>
      </div>}
    </>
  );
}
