import { FoOpacity } from "../../animations/opacity";
import { FoSlideProps } from "../../animations/slide";
import { useState } from "react";

export interface FoTooltipProps extends FoSlideProps {
    black?: boolean,
    message?: string
}

export function FoTooltip(props: FoTooltipProps) {
    const [show, setShow] = useState(false);

    return (
        <div className={`${props.className} relative`} onMouseEnter={(evt: any) => setShow(true)} onMouseLeave={(evt: any) => setShow(false)}>
            <button className="block">
                <svg className="w-4 h-4 fill-current text-contrast-400 dark:text-contrast-500" viewBox="0 0 16 16">
                    <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                </svg>
            </button>
            <FoOpacity show={show}>
                <div className={`text-xs border min-w-56 p-2 rounded overflow-hidden mb-2 shadow-lg 
                    ${props.black ? 'bg-contrast-700 border-contrast-600' : 'bg-white text-contrast-600'} `}>
                    {props.message ? props.message : props.children}
                </div>
            </FoOpacity>
        </div>
    );
}
