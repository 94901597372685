/**
 * Umbria Mobile
 * Gestione dei dati della zona SSO Umbra
 *
 * The version of the OpenAPI document: v1.0
 * Contact: support@fovea.group
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { RequestFile } from './models';
import { GlobalizationText } from './globalizationText';
import { SentieroPath } from './sentieroPath';

export class Sentiero {
    'created'?: Date;
    'updated'?: Date | null;
    'deleted'?: Date | null;
    'isDeleted'?: boolean;
    'isLock'?: boolean;
    'userId'?: string | null;
    'id'?: number;
    'sourceId'?: string | null;
    'name'?: string | null;
    'mainImage'?: string | null;
    'descrizione'?: Array<GlobalizationText> | null;
    'dislivello'?: number | null;
    'difficolta'?: string | null;
    'tipologia'?: string | null;
    'caratteristiche'?: Array<string> | null;
    'paths'?: Array<SentieroPath> | null;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "created",
            "baseName": "created",
            "type": "Date"
        },
        {
            "name": "updated",
            "baseName": "updated",
            "type": "Date"
        },
        {
            "name": "deleted",
            "baseName": "deleted",
            "type": "Date"
        },
        {
            "name": "isDeleted",
            "baseName": "isDeleted",
            "type": "boolean"
        },
        {
            "name": "isLock",
            "baseName": "isLock",
            "type": "boolean"
        },
        {
            "name": "userId",
            "baseName": "userId",
            "type": "string"
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "sourceId",
            "baseName": "sourceId",
            "type": "string"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "mainImage",
            "baseName": "mainImage",
            "type": "string"
        },
        {
            "name": "descrizione",
            "baseName": "descrizione",
            "type": "Array<GlobalizationText>"
        },
        {
            "name": "dislivello",
            "baseName": "dislivello",
            "type": "number"
        },
        {
            "name": "difficolta",
            "baseName": "difficolta",
            "type": "string"
        },
        {
            "name": "tipologia",
            "baseName": "tipologia",
            "type": "string"
        },
        {
            "name": "caratteristiche",
            "baseName": "caratteristiche",
            "type": "Array<string>"
        },
        {
            "name": "paths",
            "baseName": "paths",
            "type": "Array<SentieroPath>"
        }    ];

    static getAttributeTypeMap() {
        return Sentiero.attributeTypeMap;
    }
}

