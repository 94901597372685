import { FoUtility } from "../../core";
import { FoChildrenProps, FoStyleProps } from "../../props";
import { FoTooltip } from "../widgets/tooltip";

export interface FoFieldProps extends FoChildrenProps, FoStyleProps {
    right?: any;
    left?: any;
    invalid?: boolean;
    invalidMessage?: string;
    label?: string;
    tooltip?: string;
    onClick?: () => void;
}

export function FoField(props: FoFieldProps) {
    return (
        <div className={`w-full ${props.className}`}>
            {props.label && <h6 className={`mb-2`}>{props.label}</h6>}
            <div className="relative flex">
                <div className={`relative flex-1 fo-input ${props.onClick ? 'cursor-pointer' : ''}`} onClick={() => props.onClick?.()}>
                    {FoUtility.getChild(props.children, "left") && <div className="absolute inset-y-0 left-0 flex items-center">
                        {FoUtility.getChild(props.children, "left")}
                    </div>}
                    <div className={`${FoUtility.getChild(props.children, "left") ? 'pl-7' : ''} ${FoUtility.getChild(props.children, "right") ? 'pr-5' : ''}`}>
                        {FoUtility.getChild(props.children)}
                    </div>
                    {FoUtility.getChild(props.children, "right") && <div className="absolute inset-y-0 right-0 flex items-center">
                        {FoUtility.getChild(props.children, "right")}
                    </div>}
                    {props.invalid && <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <svg className="h-5 w-5 text-danger-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                        </svg>
                    </div>}
                </div>
                {props.tooltip && <FoTooltip className={`my-auto ml-2`} message={props.tooltip} />}
            </div>
            {props.invalid && props.invalidMessage &&
                <p className="mt-2 text-sm text-danger-500">{props.invalidMessage}</p>}
        </div>
    );
}
