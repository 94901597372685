import Detail from '../../common/detail';
import { FoTryCatch } from '../../framework/decorator/error.decorator';
import { FoLoading } from '../../framework/decorator/loading.decorator';
import { FoLoaderService } from '../../framework/services/loader.service';
import { BasePage, withParams } from '../page.base';
import { FoSpinner } from '../../framework/components/widgets/spinner';
import { FoField } from '../../framework/components/inputs/field';
import { Warning } from '../../_core/model/warning';

class WarningDetail extends BasePage<any, {
    entity: Warning
}> {
    @FoLoading("warnings")
    @FoTryCatch({ message: () => "Errore durante il recupero della segnalazione" })
    public async componentDidMount() {
        let warning = this.props.params.id == 0 ? new Warning() : await this.api.warning_read(this.props.params.id);
        this.setState({ entity: warning });
    }

    public render() {
        if (this.state.entity == null) { return <></>; }
        return <>
            <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8"><div className="px-4 sm:px-6 lg:px-8">
                {FoLoaderService.hasLoading("warnings") && <FoSpinner />}
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Segnalazione</h1>
                        {/* <p>{this.state.entity?.details?.title}</p> */}
                    </div>
                </div>
                <div className="mt-8 border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="mt-1 sm:mt-0">
                                <div className="flex">
                                    <h6>Informazioni</h6>
                                </div>
                            </dt>
                            <dd className="mt-1 mb-2 flex col-span-2 sm:mt-0 w-full">
                                <div className='space-y-4 w-4/5'>
                                    {/* <Detail name="Descrizione" inline={false}>
                                        <span>{this.state.entity?.details?.content}</span>
                                    </Detail>
                                    <Detail name="Categoria" description='Tipologia della Segnalazione' inline={true}>
                                        <span>{this.state.entity?.details?.category?.description}</span>
                                    </Detail>
                                    <Detail name="Data" description="Data di invio della segnalazione" inline={true}>
                                        <span className="flex space-x-2 items-center my-auto justify-center">
                                            <svg className='h-5 w-5' viewBox="0 0 24 24" fill="currentColor">
                                                <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
                                                <path fill-rule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clip-rule="evenodd" />
                                            </svg>
                                            {this.state.entity && <span className='align-center'>{new Intl.DateTimeFormat('it-IT', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            }).format(new Date(this.state.entity?.created!))}</span>}
                                        </span>
                                    </Detail>
                                    <Detail name="Email" description='Email del segnalante' inline={true}>
                                        <span>{this.state.entity?.details?.user}</span>
                                    </Detail> */}
                                </div>
                            </dd>
                        </div>

                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="mt-1 sm:mt-0"><h6>Allegati</h6></dt >
                            <dd className="mt-1 flex sm:col-span-2 sm:mt-0 w-full">
                                <ul role="list" className="w-full divide-y divide-gray-100 rounded-md border border-gray-200">
                                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                        <div className="flex w-0 flex-1 items-center">
                                            <svg className="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z" clip-rule="evenodd"></path>
                                            </svg>
                                            <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                <span className="truncate font-medium">resume_back_end_developer.pdf</span>
                                                <span className="flex-shrink-0 text-gray-400">2.4mb</span>
                                            </div>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                            <a href="#" className="font-medium text-primary-600 hover:text-primary-500">Download</a>
                                        </div>
                                    </li>
                                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                        <div className="flex w-0 flex-1 items-center">
                                            <svg className="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z" clip-rule="evenodd"></path>
                                            </svg>
                                            <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                <span className="truncate font-medium">coverletter_back_end_developer.pdf</span>
                                                <span className="flex-shrink-0 text-gray-400">4.5mb</span>
                                            </div>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                            <a href="#" className="font-medium text-primary-600 hover:text-primary-500">Download</a>
                                        </div>
                                    </li>
                                </ul>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div></div>
        </>;
    }
}
export default withParams(WarningDetail);
