import { useState } from "react";
import { FoChildrenProps, FoStyleProps } from "../../props";

export interface FoHeadingProps extends FoChildrenProps, FoStyleProps {
    title: string,
    subtitle?: string,
    inline?: boolean
}

export function FoHeading(props: FoHeadingProps) {
    const [show, setShow] = useState(false);

    return (
        <div className={`${props.className} border-b border-gray-200 pb-5`}>
            <div className="flex flex-wrap items-baseline my-auto">
                <div className={`${props.inline ? 'flex' : ''} sm:w-0 sm:flex-1 my-auto`}>
                    <h3>{props.title}</h3>
                    {props.subtitle && <p className={`${props.inline ? 'ml-2' : 'mt-2'} truncate`}>{props.subtitle}</p>}
                </div>
                {props.children && <div className="flex items-center justify-between my-auto">
                    {props.children}
                </div>}
            </div>
        </div>
    );
}
