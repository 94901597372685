import { FoLoaderService } from "../services/loader.service";

export function FoLoading(... name: string[]) {
    return function (target: any, key: string, descriptor: any) {
        const originalMethod = descriptor.value;
        descriptor.value = async function (...args: any[]) {
            try {
                name?.forEach(n => FoLoaderService.addLoading(n));
                this.setState({});
                return await originalMethod.apply(this, args);
              } finally {
                name?.forEach(n => FoLoaderService.removeLoading(n));
                this.setState({});
              }
        };
        return descriptor;
    };
}