import { FoUtility } from "../../core";
import { FoSize, FoState } from "../../enum";
import { FoChildrenProps, FoStyleProps } from "../../props";

export interface FoPaginationProps extends FoStyleProps {
    current?: number;
    total?: number;
    size?: number;
    next?: string;
    prev?: string;
    onClick?: (page: number) => void;
}

export function FoFlatPagination(props: FoPaginationProps) {

    let lastPage = () => { let ratio = (props.total ?? 0) / (props.size ?? 10); return Math.floor(ratio) + (ratio > Math.floor(ratio) ? 1 : 0); }
    let pages = () => { 
        let result = [];
        let last = lastPage();
        let current = props.current ?? 1;
        for(let i = 1; i <= last; i++ ) { 
            if(i == 1 || i == 2 || i == 3 || i > (current - 2) || i < (current + 2) || i < (last - 2))
                result.push(i);
            else if(result.length > 0 && result[result.length - 1] != null)
                result.push(null);
        }; 
        return result;
    }

    return (
        <>
            <nav className="flex items-center justify-between border-t border-contrast-200 px-4 sm:px-0">
                {(props.current ?? 1) != 1 && <div className="-mt-px flex w-0 flex-1">
                    <a onClick={() => props.onClick?.((props.current ?? 1) - 1)} className="cursor-pointer inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-contrast-500 hover:border-contrast-300 hover:text-contrast-700">
                        <svg className="mr-3 h-5 w-5 text-contrast-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z" clip-rule="evenodd" />
                        </svg>
                        {props.prev ?? 'Indietro'}
                    </a>
                </div>}
                {(props.current ?? 1) == 1 && <span className="-mt-px flex w-0 flex-1">&nbsp;</span>}
                <div className="hidden md:-mt-px md:flex">
                    {pages().map(page => {
                        if(page != null && page != (props.current ?? 1))
                            return <a onClick={() => props.onClick?.(page)} className="cursor-pointer inline-flex items-center border-t-2 
                                border-transparent px-4 pt-4 text-sm font-medium text-contrast-500 hover:border-contrast-300 hover:text-contrast-700">{page}</a>
                        else if(page == null)
                            <span className="cursor-default inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-contrast-500">...</span>
                        else
                            return <span className="cursor-default inline-flex items-center border-t-2 border-primary-500 
                                px-4 pt-4 text-sm font-medium text-primary-600" aria-current="page">{page}</span>
                    })}
                </div>
                {(props.current ?? 1) == lastPage() && <span className="-mt-px flex w-0 flex-1">&nbsp;</span>}
                {(props.current ?? 1) != lastPage() && <div className="-mt-px flex w-0 flex-1 justify-end">
                    <a onClick={() => props.onClick?.((props.current ?? 1) + 1)} className="cursor-pointer inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-contrast-500 hover:border-contrast-300 hover:text-contrast-700">
                        {props.next ?? 'Avanti'}
                        <svg className="ml-3 h-5 w-5 text-contrast-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z" clip-rule="evenodd" />
                        </svg>
                    </a>
                </div>}
            </nav>
        </>
    );
}
