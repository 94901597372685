export class DetailProps {
    public name!: string;
    public description?: string;
    public children?: React.ReactNode | any;
    public inline?: boolean;
    public fieldFull?: boolean;
}

export default function Detail(props: DetailProps) {
    return (
        <div className={`${props.inline ? 'flex' : '' } relative items-start`}>
            <div className={`${!props.inline ? 'flex mb-2' : '' } min-w-0 ${props.fieldFull ? '' : 'flex-1' }`}>
                <h6>{props.name}</h6>
                {props.description ? 
                    <small className={`${!props.inline ? 'leading-5 ml-2' : '' }`}>{props.inline ? props.description : "(" + props.description + ")"}</small> : "" }
            </div>
            <div className={`${props.inline ? 'ml-3' : ''} ${props.fieldFull ? 'flex-1' : '' }`}>
                {props.children}
            </div>
        </div>
    )
}
